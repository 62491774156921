<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Unauthorized 403"></page-header>
    <main class="flex-1 p-6 flex space-x-6 overflow-y-scroll">
      <div class="w-full md:w-1/2">
        <div class="bg-red-200 rounded-lg w-full px-4 py-2">
          <h2 class="text-red-600 text-xl font-bold">
            OPPSSS!!!! Sorry...
          </h2>
          <p class="text-red-800 text-lg">
            Sorry, your access is refused due to security reasons of our server
            and also our sensitive data.<br />Please go back to the previous
            page to continue browsing.
          </p>
          <p class="text-red-800 text-lg">
            If you believe there has been a mistake and you need the access,
            contact your administrator to get it fixed.
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
export default {
  name: "unauthorized",
  components: {
    PageHeader,
  },
};
</script>
