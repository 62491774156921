import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import AuthService from "@/services/authService";
import UnAuthorized from "@/components/Error/403";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        // console.log("to", to);
        // console.log("from", from);
        // console.log("savedPosition", savedPosition);
        if (savedPosition) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(savedPosition);
                }, 1000);
            });
        } else {
            return {y: 0};
        }
    },
    routes: [
        {
            path: "/login",
            component: () => import("@/views/Login.vue"),
        },
        {
            path: "/authcallback",
            component: () => import("@/views/AuthCallback.vue"),
        },
        {
            path: "/",
            name: "dashboard",
            component: () => import("@/views/Dashboard.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/contacts",
            name: "contacts",
            component: () => import("@/views/Contacts/Index.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/contacts/:contactId",
            name: "contacts-edit",
            component: () => import("@/views/Contacts/EditContact.vue"),
            props: true,
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/prospects",
            name: "prospect-index",
            component: () => import("@/views/SalesProspects/Index.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/prospects/create",
            name: "prospect-create",
            component: () => import("@/views/SalesProspects/CreateProspect.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/prospects/:prospectId",
            name: "prospect-edit",
            component: () => import("@/views/SalesProspects/EditProspect.vue"),
            meta: {
                requiresAuth: true,
            },
            props: true,
        },
        {
            path: "/products",
            name: "product-index",
            component: () => import("@/views/Products/Index.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/products/create",
            name: "product-create",
            component: () => import("@/views/Products/CreateProduct.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/products/:productCode",
            name: "product-edit",
            component: () => import("@/views/Products/EditProduct.vue"),
            meta: {
                requiresAuth: true,
            },
            props: true,
        },
        {
            path: "/tickets",
            name: "service-tickets",
            component: () => import("@/views/ServiceTickets/Index.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/tickets/:ticketId",
            name: "service-ticket-edit",
            component: () => import("@/views/ServiceTickets/EditServiceTicket.vue"),
            meta: {
                requiresAuth: true,
            },
            props: true,
        },
        {
            path: "/documents",
            name: "documents",
            component: () => import("@/views/Documents/Index.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/equipment",
            name: "equipment-index",
            component: () => import("@/views/Equipment/EquipmentIndex.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/equipment/:equipmentId",
            name: "equipment-detail",
            component: () => import("@/views/Equipment/EquipmentDetail.vue"),
            meta: {
                requiresAuth: true,
            },
            props: true,
        },
        /** Tools module **/
        {
            path: "/tools",
            name: "tools",
            component: () => import("@/views/Tools/Tools.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/tools/transactions",
            name: "stripe-transactions",
            component: () => import("@/views/Tools/StripeTransactions/Index.vue"),
            meta: {
                requiresAuth: true,
                requiresPermission: "manage:stripe",
            },
            requiresPermission: "manage:stripe",
        },
        {
            path: "/tools/transactions/:transactionId",
            name: "view-stripe-transaction",
            component: () =>
                import("@/views/Tools/StripeTransactions/ViewStripeTransaction.vue"),
            meta: {
                requiresAuth: true,
                requiresPermission: "manage:stripe",
            },
            props: true,
        },

        {
            path: "/tools/returncodes",
            name: "return-codes",
            component: () => import("@/views/Tools/ReturnCodes/Index.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/tools/workorders",
            name: "tools-workorder-index",
            component: () => import("@/views/Tools/WorkOrders/Index.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/tools/workorders/:orderId",
            name: "tools-workorder-details",
            component: () => import("@/views/Tools/WorkOrders/Details.vue"),
            props: true,
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        /** SW900 module **/
        {
            path: "/tools/ivycodegen",
            name: "tools-ivycodegen",
            component: () => import("@/views/Tools/IvyCodeGen.vue"),
        },
        {
            path: "/tools/ivyclients",
            name: "tools-ivy-clients",
            component: () => import("@/views/Tools/IvyClients/IvyClients.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/tools/ivyclients/:clientId",
            name: "tools-ivy-clients-detail",
            component: () => import("@/views/Tools/IvyClients/IvyClientDetail.vue"),
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/tools/ivyevents",
            name: "tools-ivy-events",
            component: () => import("@/views/Tools/IvyEvents.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/tools/ivylogins",
            name: "tools-ivy-logins",
            component: () => import("@/views/Tools/IvyLogins.vue"),
            meta: {
                requiresAuth: true,
            },
            requiresAuth: true,
        },
        {
            path: "/internal",
            name: "internal",
            component: () => import("@/views/HR/HR.vue"),
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:internal",
                    "create:internal",
                    "read:internal",
                    "update:internal",
                    "delete:internal",
                ],
            },
        },
        {
            path: "/internal/fleets",
            name: "fleets",
            component: () => import("@/views/HR/Fleets/Index.vue"),
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:fleets",
                    "create:fleets",
                    "read:fleets",
                    "update:fleets",
                    "delete:fleets",
                ],
            },
        },
        {
            path: "/internal/fleets/:fleetId",
            name: "fleets-edit",
            component: () => import("@/views/HR/Fleets/EditView.vue"),
            props: true,
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:fleets",
                    "create:fleets",
                    "read:fleets",
                    "update:fleets",
                    "delete:fleets",
                ],
            },
        },
        {
            path: "/internal/fleet/create",
            name: "vehicle-create",
            component: () => import("@/views/HR/Fleets/CreateVehicle.vue"),
            props: true,
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: ["manage:fleets", "create:fleets"],
            },
            requiresPermission: ["manage:fleets", "create:fleets"],
        },
        {
            path: "/internal/staff",
            name: "staff",
            component: () => import("@/views/HR/Staff/Index.vue"),
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:staff",
                    "create:staff",
                    "read:staff",
                    "update:staff",
                    "delete:staff",
                ],
            },
        },
        {
            path: "/internal/staff/create",
            name: "staff-create",
            component: () => import("@/views/HR/Staff/CreateStaff.vue"),
            props: true,
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: ["manage:staff", "create:staff"],
            },
            requiresPermission: ["manage:staff", "create:staff"],
        },
        {
            path: "/internal/staff/:staffId",
            name: "staff-edit",
            component: () => import("@/views/HR/Staff/EditStaff.vue"),
            props: true,
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:staff",
                    "create:staff",
                    "read:staff",
                    "update:staff",
                    "delete:staff",
                ],
            },
        },
        {
            path: "/internal/holiday",
            name: "staff-holiday",
            component: () => import("@/views/HR/HolidayPlanner/Index.vue"),
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:leaveOwn",
                    "manage:leaveAll",
                    "manage:holidays",
                    "create:staff",
                    "read:holidays",
                    "update:holidays",
                    "delete:holidays",
                ],
            },
        },
        {
            path: "/internal/documents",
            name: "internaldocs",
            component: () => import("@/views/HR/InternalDocuments/Index.vue"),
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:internal_docs",
                    "create:internal_docs",
                    "read:internal_docs",
                    "update:internal_docs",
                    "delete:internal_docs",
                ],
            },
        },
        {
            path: "/internal/healthnsafety",
            name: "healthnsafety",
            component: () => import("@/views/HR/HealthAndSafety/Index.vue"),
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:incident",
                    "create:incident",
                    "read:incident",
                    "update:incident",
                    "delete:incident",
                ],
            },
        },
        {
            path: "/internal/healthnsafety/incident",
            name: "incident",
            component: () => import("@/views/HR/HealthAndSafety/Incident/Index.vue"),
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: [
                    "manage:incident",
                    "create:incident",
                    "read:incident",
                    "update:incident",
                    "delete:incident",
                ],
            },
        },
        {
            path: "/internal/healthnsafety/incident/create/:incident_id?",
            name: "incident-create",
            component: () =>
                import("@/views/HR/HealthAndSafety/Incident/CreateIncident.vue"),
            props: true,
            meta: {
                requiresAuth: true,
                requiresRole: "hr_manager",
                requiresPermission: ["manage:incident", "create:incident"],
            },
            requiresPermission: ["manage:incident", "create:incident"],
        },
        {
            path: "/unauthorized",
            name: "unauthorized",
            component: UnAuthorized,
        },
        {
            path: "/faqs",
            name: "faqs",
            component: () => import("@/views/faqs/Index.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/faqs/:faqId",
            name: "faq-edit",
            component: () => import("@/views/faqs/EditFaqs.vue"),
            meta: {
                requiresAuth: true,
            },
            props: true,
        },
        {
            // List of goods
            path: "/internal/goods-in",
            name: "goods-in",
            component: () => import("@/views/HR/GoodsIn/Index.vue"),
            meta: {},
        },
        {
            // Edit specific goods
            path: "/internal/goods-in/edit/:goods_in_id",
            name: "goods-in-edit",
            component: () => import("@/views/HR/GoodsIn/CreateGoodsIn.vue"),
            props: true,
            meta: {},
        },
        {
            // Create new goods
            path: "/internal/goods-in/create/",
            name: "goods-in-create",
            component: () => import("@/views/HR/GoodsIn/CreateGoodsIn.vue"),
            meta: {},
        },
        {
            path: "/internal/loan-equipment",
            name: "loan-equipment",
            component: () => import("@/views/LoanEquipment/Index.vue"),
            meta: {},
        },
        {
            // Create new goods
            path: "/internal/loan-equipment/create/",
            name: "loan-equipment-create",
            component: () => import("@/views/LoanEquipment/CreateLoanEquipmentLocation.vue"),
            meta: {},
        },
        {
            // Edit specific goods
            path: "/internal/loan-equipment/edit/:loanEquipmentId",
            name: "loan-equipment-edit",
            component: () => import("@/views/LoanEquipment/CreateLoanEquipmentLocation.vue"),
            props: true,
            meta: {},
        },
        //service
        {
            path: "/internal/service",
            name: "service",
            component: () => import("@/views/HR/Engineer/Index.vue"),
            meta: {},
        },
        //schedule
        {
            path: "/internal/service/schedule/view",
            name: "schedule",
            component: () => import("@/views/HR/Engineer/Schedule/Index.vue"),
            meta: {},
        },
        {
            path: "/internal/service/schedule/edit/:schedule_id",
            name: "schedule-edit",
            component: () => import("@/views/HR/Engineer/Schedule/Create.vue"),
            props: true,
            meta: {},
        },
        {
            path: "/internal/service/schedule/create",
            name: "schedule-create",
            component: () => import("@/views/HR/Engineer/Schedule/Create.vue"),
            props: route => ({customer_id: route.query.customer_id}),
            meta: {},
        },
        {
            path: "/internal/service/schedule/view/:job_id",
            name: "engineer-schedule-view",
            component: () => import("@/views/HR/Engineer/Schedule/Create.vue"),
            props: true,
            meta: {},
        },
        {
            path: "/internal/service/job-sheet/view",
            name: "job-sheet",
            component: () => import("@/views/HR/Engineer/JobSheet/Index.vue"),
            meta: {},
        },
        {
            path: "/internal/service/job-sheet/create",
            name: "engineer-job-sheet-create",
            component: () => import("@/views/HR/Engineer/JobSheet/Create.vue"),
            props: (route) => ({
                job_id: route.query.job_id,
                equipment_id: route.query.equipment_id,
                product_code: route.query.product_code
            }),
            meta: {},
        },
        {
            path: "/internal/service/job-sheet/edit/:job_sheet_id",
            name: "engineer-job-sheet-edit",
            component: () => import("@/views/HR/Engineer/JobSheet/Create.vue"),
            props: true,
            meta: {},
        },

        //my-schedule
        {
            path: "/internal/service/my-schedule/view",
            name: "my-schedule",
            component: () => import("@/views/HR/Engineer/MySchedule/Index.vue"),
            meta: {},
        },
        //repair
        {
            path: "/internal/repair",
            name: "repairs",
            component: () => import("@/views/HR/Repair/Index.vue"),
            meta: {},
        },
        {
            path: "/internal/repair/create",
            name: "repair-create",
            component: () => import("@/views/HR/Repair/Create.vue"),
            props: (route) => ({
                equipment_id: route.query.equipment_id,
                product_code: route.query.product_code
            }),
            meta: {},
        },
        {
            path: "/internal/repair/edit/:repair_id",
            name: "repair-edit",
            component: () => import("@/views/HR/Repair/Create.vue"),
            props: true,
            meta: {},
        },
        {
            path: "/internal/quotation/create",
            name: "quotation-create",
            component: () => import("@/views/HR/Quotation/Create.vue"),
            props: (route) => ({
                equipment_id: route.query.equipment_id,
                repair_id: route.query.repair_id,
                product_code: route.query.product_code,
            }),
            meta: {},
        },

        {
            path: "/internal/quotation/edit/:quotation_id",
            name: "quotation-edit",
            component: () => import("@/views/HR/Quotation/Create.vue"),
            props: true,
            meta: {},
        },
        {
            path: "/settings",
            name: "settings",
            component: () => import("@/views/Settings/Index.vue"),
            meta: {
                requiresAuth: true,
            },
        },

        //schedule
        {
            path: "/internal/service/certificate/view",
            name: "certificate",
            component: () => import("@/views/HR/Engineer/Certificate/Index.vue"),
            meta: {},
        },

        {
            path: "/internal/service/certificate/edit/:certificate_id",
            name: "certificate-edit",
            component: () => import("@/views/HR/Engineer/Certificate/Create.vue"),
            props: true,
            meta: {},
        },
        {
            // Create new goods
            path: "/internal/service/certificate/create/",
            name: "certificate-create",
            component: () => import("@/views/HR/Engineer/Certificate/Create.vue"),
            meta: {},
        },

        //purchases
        {
            path: "/internal/purchase",
            name: "purchase",
            component: () => import("@/views/HR/Purchases/Index.vue"),
            meta: {},
        },
        {
            path: "/internal/purchase/create",
            name: "purchase-create",
            component: () => import("@/views/HR/Purchases/Create.vue"),
            meta: {},
        },
        {
            path: "/internal/purchase/edit/:purchase_order_id",
            name: "purchase-edit",
            component: () => import("@/views/HR/Purchases/Create.vue"),
            props: true,
            meta: {},
        },

        //attendance
        {
            path: "/internal/attendance",
            name: "attendance",
            component: () => import("@/views/HR/Attendance/Index.vue"),
            meta: {},
        },
        //sales
        {
            path: "/internal/sales",
            name: "sales",
            component: () => import("@/views/HR/Sales/Index.vue"),
            meta: {},
        },

        {
            path: "/internal/sales/create",
            name: "sales-create",
            component: () => import("@/views/HR/Sales/Create.vue"),
            meta: {},
        },
        {
            path: "/internal/sales/edit/:sales_order_id",
            name: "sales-edit",
            component: () => import("@/views/HR/Sales/Create.vue"),
            props: true,
            meta: {},
        },
        {
            path: "/internal/sales/:sales_order_id/dispatch/:dispatch_note_id",
            name: "dispatch",
            component: () => import("@/views/HR/Sales/Dispatch/Create.vue"),
            props: true,
            meta: {},
        },
        {
            path: "/internal/sales/:sales_order_id/dispatch/:dispatch_note_id/pack-list/:pack_list_id",
            name: "pack-list",
            component: () => import("@/views/HR/Sales/PackList/Create.vue"),
            props: true,
            meta: {},
        },

    ],
});

router.beforeEach((to, from, next) => {
    if (store.getters.isFormDirty) {
        store.dispatch("setNavPrevented");
        store.dispatch("setNavPreventedTo", to.name);
    } else {
        if (to.meta.requiresAuth && !AuthService.isLoggedIn()) {
            next("/login");
        }
        if (
            to.meta.requiresPermission &&
            !AuthService.permissionCheck(to.meta.requiresPermission)
        ) {
            next("/unauthorized");
        } else {
            next();
        }

        // if (to.meta.requiresRole && !AuthService.roleCheck(to.meta.requiresRole)) {
        //   next("/unauthorized");
        // } else {
        //   next();
        // }
    }
});

export default router;
