import axios from "axios";

const usersUrl = "/v2/api/vtms/localusers";

class EngineerService {
  // Get all engineers
  static getEngineers(options) {
    return new Promise(async (resolve, reject) => {
      try {
        options = options || {};

        let url = [
          usersUrl,
          `?page=${options.currentPage || 1}`,
          `&limit=${options.pageSize || 50}`,
          `&orderBy=${options.field || "org_name"}`,
          `&order=${options.isAsc ? "asc" : "desc"}`,
        ].join("");
        let result = await axios.get(url);
        resolve(result.data);
      } catch (err) {
        reject(err.response);
      }
    });
  }

  // Create engineer
  static createEngineer(engineer) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(usersUrl, engineer);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Update engineer
  static updateEngineer(engineer) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(
          `${usersUrl}/${engineer.user_id}`,
          engineer
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}

export default EngineerService;
