import repairService from "@/services/repairService";

export const RepairStore = {
    namespaced: true,
    state: {
        tabs: {
            all: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchText: "",
                sortField: "next_calibration",
                sortOrder: "asc",
            },
            review: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchText: "",
                sortField: "next_calibration",
                sortOrder: "asc",
            },
            quotation: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchText: "",
                sortField: "next_calibration",
                sortOrder: "asc",
            },
            completed: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchText: "",
                sortField: "next_calibration",
                sortOrder: "asc",
            },
        },
        currentTab: "all",
        searchText: "",
        isLoading: false,
    },
    mutations: {
        SET_TABLE_DATA(state, {tab, data}) {
            state.tabs[tab].tableData = data.data;
            state.tabs[tab].totalRecords = data.count;
        },
        UPDATE_CURRENT_PAGE(state, {tab, page}) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_ROWS_PER_PAGE(state, {tab, rows}) {
            state.tabs[tab].rowsPerPage = rows;
        },
        UPDATE_SORT_OPTIONS(state, {tab, field, order}) {
            state.tabs[tab].sortField = field;
            state.tabs[tab].sortOrder = order;
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        UPDATE_SEARCH_TEXT(state, {tab, searchText}) {
            state.tabs[tab].searchText = searchText;
        },
    },
    actions: {
        async fetchTableData({commit, state}) {
            commit("SET_LOADING", true);

            const currentTabState = state.tabs[state.currentTab];

            let params = buildParams(currentTabState, state);

            const apiEndpoint = getApiEndpoint();
            const queryString = buildQueryString(params);

            try {
                let data = await apiEndpoint(`?${queryString}`);
                commit("SET_TABLE_DATA", {tab: state.currentTab, data});
            } catch (error) {
                commit("SET_TABLE_DATA", {tab: state.currentTab, data: []});
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        updateTableSettings({commit, dispatch, state}, {rows, page}) {
            commit("UPDATE_ROWS_PER_PAGE", {tab: state.currentTab, rows});
            commit("UPDATE_CURRENT_PAGE", {tab: state.currentTab, page: page + 1});
            dispatch("fetchTableData");
        },
        updateSortOptions({commit, dispatch, state}, {field, order}) {
            commit("UPDATE_SORT_OPTIONS", {tab: state.currentTab, field, order});
            dispatch("fetchTableData");
        },
        updateSearchCriteria({commit, state}, {
            searchText,
        }) {
            const tab = state.currentTab;
            commit("UPDATE_SEARCH_TEXT", {tab, searchText});
        },
        updateTab({commit, dispatch}, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },

    },
};

function buildQueryString(params) {
    return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}

function buildParams(currentTabState, state) {

    let params = {
        page: currentTabState.currentPage,
        limit: currentTabState.rowsPerPage,
        orderBy: currentTabState.sortField,
        order: currentTabState.sortOrder,
        workflow_status: state.currentTab,
    };

    if (currentTabState.searchText) {
        params.searchTerm = currentTabState.searchText;
    }

    return params;
}

function getApiEndpoint() {
    return repairService.getRepairs;
}


