import axios from "axios";

const ivyReturnCodeUrl = "/api/responsecodes";
const codesUrl = "/v2/api/returncodes";

class ReturnCodeService {
  // Log Return Code
  static logReturnCode(returnCode) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(codesUrl, returnCode);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Return code
  static getLoggedCodes(pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};

        let url = [
          codesUrl,
          `?page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
        ].join("");
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Return code
  static getIvyResponseCode(applicationType, challengeCode) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(ivyReturnCodeUrl, {
          application_type: applicationType,
          challenge_code: challengeCode,
        });
        resolve(result.data);
      } catch (err) {
        reject(err.response);
      }
    });
  }
}

export default ReturnCodeService;
