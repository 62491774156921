import moment from "moment";
import PurchaseOrderService from "@/services/purchaseService";

export const PurchaseStore = {
    namespaced: true,
    state: {
        tabs: {
            all_purchases: {
                purchase_order_id: null,
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "order_date",
                sortOrder: "-1",
                first:0,
            },
            pending: {
                purchase_order_id: null,
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "order_date",
                sortOrder: "-1",
                first:0,
            },
            all_received: {
                purchase_order_id: null,
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "order_date",
                sortOrder: "-1",
                first:0,
            },
            approved: {
                purchase_order_id: null,
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "order_date",
                sortOrder: "-1",
                first:0,
            },
        },
        currentTab: "all_purchases",
        searchText: "",
        fromDate: null,
        toDate: null,
        isLoading: false,
        lastSelectedPurchase: '',
    },
    mutations: {
        SET_TABLE_DATA(state, {tab, data}) {
            state.tabs[tab].tableData = data.data;
            state.tabs[tab].totalRecords = data.count;
        },
        UPDATE_CURRENT_PAGE(state, {tab, page}) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_ROWS_PER_PAGE(state, {tab, rows}) {
            state.tabs[tab].rowsPerPage = rows;
        },
        UPDATE_SORT_OPTIONS(state, {tab, field, order}) {
            state.tabs[tab].sortField = field;
            state.tabs[tab].sortOrder = order;
        },
        RESET_FIRST_VALUE(state, { tab }) {
            const currentPage = state.tabs[tab].currentPage;
            const rowsPerPage = state.tabs[tab].rowsPerPage;
            state.tabs[tab].first = currentPage > 0 ? (currentPage - 1) * rowsPerPage : 0;
        },
        RESET_CURRENT_PAGE(state, { tab, page = 0 }) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_SEARCH_TEXT(state, searchText) {
            state.searchText = searchText;
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        UPDATE_FROM_DATE(state, fromDate) {
            state.fromDate = fromDate;
        },
        UPDATE_TO_DATE(state, toDate) {
            state.toDate = toDate;
        },
        SET_LAST_SELECTED_PURCHASE(state, lastPurchaseId) {
            state.lastSelectedPurchase = lastPurchaseId;
        },
    },
    actions: {
        async fetchTableData({commit, state,dispatch}) {
            commit("SET_LOADING", true);
            const currentTabState = state.tabs[state.currentTab];
            
            let params = buildParams(currentTabState, state);
            const apiEndpoint = getApiEndpoint();
            const queryString = buildQueryString(params);

            try {
                let data = await apiEndpoint(`?${queryString}`);

                commit("SET_TABLE_DATA", {tab: state.currentTab, data});
            } catch (error) {
                commit("SET_TABLE_DATA", {tab: state.currentTab, data: []});
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
                dispatch("resetFirstValue");
            }
        },
        updateTableSettings({commit, dispatch, state}, {rows, page}) {
            commit("UPDATE_ROWS_PER_PAGE", {tab: state.currentTab, rows});
            commit("UPDATE_CURRENT_PAGE", {tab: state.currentTab, page: page + 1});
            dispatch("fetchTableData");
        },
        updateSortOptions({commit, dispatch, state}, {field, order}) {
            commit("UPDATE_SORT_OPTIONS", {tab: state.currentTab, field, order});
            dispatch("fetchTableData");
        },
        updateSearchCriteria({commit}, {searchText, selectedEngineer, fromDate, toDate}) {
            commit("UPDATE_SEARCH_TEXT", searchText);
            commit("UPDATE_FROM_DATE", fromDate);
            commit("UPDATE_TO_DATE", toDate);
            dispatch("fetchTableData");
        },
        updateTab({commit, dispatch}, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },
        resetFirstValue({ commit, state }) {
            commit("RESET_FIRST_VALUE", { tab: state.currentTab });
        },
        resetCurrentPage({ commit, state }, page = 0) {
            commit("RESET_CURRENT_PAGE", { tab: state.currentTab, page });
        },
    },
};

function buildQueryString(params) {
    return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}

function buildParams(currentTabState, state) {
    let params = {
        page: currentTabState.currentPage,
        limit: currentTabState.rowsPerPage,
        orderBy: currentTabState.sortField,
        order: currentTabState.sortOrder,
        tab: state.currentTab,
    };

    if (state.searchText) {
        params.searchTerm = state.searchText;
    }

    if (state.fromDate) {
        params.fromDate = moment(state.fromDate).format('DD/MM/YYYY');
    }

    if (state.toDate) {
        params.toDate = moment(state.toDate).format('DD/MM/YYYY');
    }

    // Add status based on the current tab
    switch (state.currentTab) {
        case 'pending':
            params.status = ['part received', 'items added', 'pending'];
            break;
        case 'all_received':
            params.status = ['received'];
            break;
        case 'approved':
            params.status = ['approved'];
            break;
        // No need to add a status filter for 'all_purchases'
        default:
            break;
    }

    return params;
}

function getApiEndpoint() {
    return PurchaseOrderService.searchAllPurchaseOrders;
}
