import axios from "axios";
import store from "@/store";

const faqUrl = "/v2/api/faq";

class FAQService {
  static createFaq(faq) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(faqUrl, faq);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static editFaq(faq) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${faqUrl}/${faq.faq_id}`, faq);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static getFaqById(faq_id) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${faqUrl}/${faq_id}`
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deleteFaqById(faq_id) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.delete(
          `${faqUrl}/${faq_id}`
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updateServiceTicket(ticket_id, ticket) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${faqUrl}/${ticket_id}`, ticket);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static closeTicket(ticket_id) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${faqUrl}/${ticket_id}/close`);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static setIsNewStatus(ticket_id, status) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${faqUrl}/${ticket_id}`, {
          is_new: status,
        });
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get FAQs
  static getFAQs(pagingOpts, category, searchTerm) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        category = category || "";
        // console.log(
        //   `[serviceTicketService] getServiceTickets: ContactId passed in is: ${category}`
        // );
        let url = [
          faqUrl,
          `?page=${pagingOpts.page || 1}`,
          searchTerm ? `&searchTerm=${searchTerm}` : "",
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
          `&category=${category}`,
        ].join("");
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }
  static getFAQsByProductCode(pagingOpts, product) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        let url = [
          `${faqUrl}/${product}/faqs`,
          `?page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`
        ].join("");
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // static getFAQs() {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let url = faqUrl;
  //       let result = await axios.get(url);
  //       resolve(result.data);
  //     } catch (error) {
  //       reject(error.response);
  //     }
  //   });
  // }

  static loadCategories() {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${faqUrl}/master/categories`;
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static loadVisiblityOptions() {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${faqUrl}/master/visibility-options`;
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static createComment(faq_id, comment) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(
          `${faqUrl}/${faq_id}/comment`,
          comment
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updateComment(faq_id, comment) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(
          `${faqUrl}/${faq_id}/comment/${comment.comment_id}`,
          comment
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static getCommentsByfaqId(faq_id) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${faqUrl}/${faq_id}/comment`
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deleteComment(faq_id, comment_id) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.delete(
          `${faqUrl}/${faq_id}/comment/${comment_id}`
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static getDocuments(faqId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(`${faqUrl}/${faqId}/documents`);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updateDocument(faqId, documentId, body) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${faqUrl}/${faqId}/documents/${documentId}`;
        let result = await axios.put(url, body);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deleteDocumentById(faqId, documentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${faqUrl}/${faqId}/documents/${documentId}`;
        let result = await axios.delete(url);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Document by Id
  static getDocumentById(faqId, documentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${faqUrl}/${faqId}/documents/${documentId}`
        );

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static getFAQsByProduct(product) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(`${faqUrl}/${product}/faqs`);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}

export default FAQService;
