import axios from "axios";

const goodsInUrl = "/v2/api/goods-in";

class GoodsInService {
    static getGoodsIn(urlP) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = [goodsInUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getGoodsInById(goodsInId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${goodsInUrl}/${goodsInId}`;
                const result = await axios.get(url);
                const goodsInData = result.data;
                resolve(goodsInData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createGoodsIn(goodsInData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${goodsInUrl}`;
                const result = await axios.post(url, goodsInData);
                const createdGoodsIn = result.data;
                resolve(createdGoodsIn);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateGoodsIn(goodsInId, updatedGoodsInData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${goodsInUrl}/${goodsInId}`;
                await axios.put(url, updatedGoodsInData);
                resolve(`Goods In record with ID ${goodsInId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteGoodsIn(goodsInId, updatedGoodsInData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${goodsInUrl}/${goodsInId}`;
                await axios.delete(url);
                resolve(`Goods In record with ID ${goodsInId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static uploadDocuments(files, goodsInId) {
        return new Promise(async (resolve, reject) => {
            try {
                // Prepare an array of file data objects
                const filesToUpload = files.map((file) => ({
                    filename: file.name,
                    content_type: file.content_type,
                    visibility: "Internal",
                    category: "goods-in",
                    name: file.name,
                    unique_id: file.unique_id,
                }));

                console.log("Files are:", files);
                console.log("Documents to upload are:", filesToUpload);

                const goodsInDocumentUrl = `${goodsInUrl}/${goodsInId}/documents`;

                // Post the array of file data objects to the server
                let result = await axios.post(goodsInDocumentUrl, filesToUpload);
                console.log(result);
                resolve(result.data);
            } catch (error) {
                console.error("Error uploading documents:", error);
                reject(error.response);
            }
        });
    }

    static async getGoodsInDocuments(goodsInId) {
        try {
            const goodsInDocumentUrl = `${goodsInUrl}/${goodsInId}/documents`;

            // Fetch the documents
            let result = await axios.get(goodsInDocumentUrl);
            return result.data;
        } catch (error) {
            console.error("Error fetching goods-in documents:", error);
            throw error; // Rethrowing the error for the caller to handle
        }
    }

    static async deleteGoodsInDocuments(goodsInId, documentIds) {
        try {
            const goodsInDocumentUrl = `${goodsInUrl}/${goodsInId}/documents`;

            // Prepare the request body
            await axios.delete(goodsInDocumentUrl, {
                data: documentIds,
                headers: {"Content-Type": "application/json"},
            });

            console.log(`Documents deleted successfully for goodsInId: ${goodsInId}`);
        } catch (error) {
            console.error("Error deleting goods-in documents:", error);
            throw error; // Rethrowing the error for the caller to handle
        }
    }
}

export default GoodsInService;
