import axios from "axios";

const documentUrl = "/v2/api/documents";

class DocumentService {
  // Get all Documents
  static getDocuments(options) {
    return new Promise(async (resolve, reject) => {
      try {
        options = options || {};
        console.log(options);

        let url = [
          documentUrl,
          `?publishedOnly=false`,
          `&page=${options.currentPage || 1}`,
          `&limit=${options.pageSize || 50}`,
          `&orderBy=${options.field || "created_at"}`,
          `&order=${options.isAsc ? "asc" : "desc"}`,
          `&category=${options.category}`,
        ].join("");

        let result = await axios.get(url);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Document by Id
  static getDocumentById(documentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(`${documentUrl}/${documentId}`);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Search Documents
  static searchDocuments(searchTerm) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(`${documentUrl}?searchTerm=${searchTerm}`);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Uploads the document
  static uploadDocument(document) {
    return new Promise(async (resolve, reject) => {
      try {
        let docToUpload = {
          filename: document.file[0].name,
          content_type: document.file[0].type,
          visibility: document.visibility,
          category: document.category,
          name: document.name,
          description: document.description,
          document_number: document.document_number,
          is_published: true,
          resource_tags: document.resource_tags || []
        };
        console.group("[DocumentService] uploadDocument");
        console.log("File is:", document.file[0]);
        console.log("Document is:", docToUpload);
        console.groupEnd();
        let result = await axios.post(documentUrl, docToUpload);
        console.log(result);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updateDocument(documentId, body) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${documentUrl}/${documentId}`;
        let result = await axios.put(url, body);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deleteDocument(documentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${documentUrl}/${documentId}`;
        let result = await axios.delete(url);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static attachResource(documentId, body) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${documentUrl}/${documentId}/resourcetags`;

        let result = await axios.put(url, body);

        resolve(result);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deleteResource(documentId, tagId, body) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${documentUrl}/${documentId}/resourcetags/${tagId}`;

        let result = await axios.delete(url, body);

        resolve(result);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}

export default DocumentService;
