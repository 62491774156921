import CertificateService from "@/services/certificateService";
import moment from "moment";

export const CertificateStore = {
    namespaced: true,
    state: {
        tabs: {
            all_certificates: {
                certificate_id: null,
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "issue_date",
                sortOrder: "asc",
            },
        },
        currentTab: "all_certificates",
        searchText: "",
        selectedEngineer: null,
        fromDate: null,
        toDate: null,
        isLoading: false,
    },
    mutations: {
        SET_TABLE_DATA(state, {tab, data}) {
            state.tabs[tab].tableData = data.data;
            state.tabs[tab].totalRecords = data.count;
        },
        UPDATE_CURRENT_PAGE(state, {tab, page}) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_ROWS_PER_PAGE(state, {tab, rows}) {
            state.tabs[tab].rowsPerPage = rows;
        },
        UPDATE_SORT_OPTIONS(state, {tab, field, order}) {
            state.tabs[tab].sortField = field;
            state.tabs[tab].sortOrder = order;
        },
        UPDATE_SEARCH_TEXT(state, searchText) {
            state.searchText = searchText;
        },
        UPDATE_SELECTED_ENGINEER(state, selectedEngineer) {
            state.selectedEngineer = selectedEngineer;
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        UPDATE_FROM_DATE(state, fromDate) {
            state.fromDate = fromDate;
        },
        UPDATE_TO_DATE(state, toDate) {
            state.toDate = toDate;
        },
    },
    actions: {
        async fetchTableData({commit, state}) {
            commit("SET_LOADING", true);
            const currentTabState = state.tabs[state.currentTab];
            let params = buildParams(currentTabState, state);
            const apiEndpoint = getApiEndpoint();
            const queryString = buildQueryString(params);

            try {
                let data = await apiEndpoint(`?${queryString}`);
                commit("SET_TABLE_DATA", {tab: state.currentTab, data});
            } catch (error) {
                commit("SET_TABLE_DATA", {tab: state.currentTab, data: []});
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
            }
        },
        updateTableSettings({commit, dispatch, state}, {rows, page}) {
            commit("UPDATE_ROWS_PER_PAGE", {tab: state.currentTab, rows});
            commit("UPDATE_CURRENT_PAGE", {tab: state.currentTab, page: page + 1});
            dispatch("fetchTableData");
        },
        updateSortOptions({commit, dispatch, state}, {field, order}) {
            commit("UPDATE_SORT_OPTIONS", {tab: state.currentTab, field, order});
            dispatch("fetchTableData");
        },
        updateSearchCriteria({commit}, {searchText, selectedEngineer, fromDate, toDate}) {
            commit("UPDATE_SEARCH_TEXT", searchText);
            commit("UPDATE_SELECTED_ENGINEER", selectedEngineer);
            commit("UPDATE_FROM_DATE", fromDate);
            commit("UPDATE_TO_DATE", toDate);
            dispatch("fetchTableData");
        },
        updateTab({commit, dispatch}, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },
    },
};

function buildQueryString(params) {
    return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}

function buildParams(currentTabState, state) {
    let params = {
        page: currentTabState.currentPage,
        limit: currentTabState.rowsPerPage,
        orderBy: currentTabState.sortField,
        order: currentTabState.sortOrder,
        tab: state.currentTab,
    };

    if (state.searchText) {
        params.searchTerm = state.searchText;
    }

    if (state.fromDate) {
        params.fromDate = moment(state.fromDate).format('DD/MM/YYYY');
    }

    if (state.toDate) {
        params.toDate = moment(state.toDate).format('DD/MM/YYYY');
    }

    if (state.selectedEngineer && state.selectedEngineer.staff_id) {
        params.engineer_id = state.selectedEngineer.staff_id;
    }

    return params;
}

function getApiEndpoint() {
    return CertificateService.searchAllCertificates;
}
