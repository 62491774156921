import HolidayService from "../../../services/holidayService";
import moment from "moment";

export const leavesStore = {
  namespaced: true,
  state: {
    leaves: [],
    totalRecords: 0,
    searchState: "Idle", // 'Idle', 'Searching', or 'Error'
    department: null,
    status: null,
    currentTab: null,
    page: 1,
    perPage: 10,
    selectedStaff: null,
  },
  mutations: {
    SET_LEAVES(state, payload) {
      state.leaves = payload;
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
    },
    SET_SEARCH_STATE(state, payload) {
      state.searchState = payload;
    },
    SET_DEPARTMENT(state, department) {
      state.department = department;
    },
    SET_STATUS(state, status) {
      state.status = status;
    },
    SET_CURRENT_TAB(state, currentTab) {
      state.currentTab = currentTab;
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_PER_PAGE(state, perPage) {
      state.perPage = perPage;
    },
    SET_SELECTED_STAFF(state, staff) {
      state.selectedStaff = staff;
    },
  },
  actions: {
    async fetchLeaves({ commit, state }) {
      commit("SET_SEARCH_STATE", "Searching");

      try {
        const params = {
          page: state.page,
          limit: state.perPage,
          name: state.currentTab,
        };

        const response = await HolidayService.searchAllLeaves(
          "onlyLeaves",
          params,
          state.selectedStaff,
        );

        if (response.data) {
          commit("SET_LEAVES", response.data);
          commit("SET_TOTAL_RECORDS", response.data.count);
          commit("SET_SEARCH_STATE", "Idle");
        } else {
          commit("SET_SEARCH_STATE", "Error");
        }
      } catch (error) {
        console.error(error);
        commit("SET_SEARCH_STATE", "Error");
      }
    },
    updateDepartment({ commit }, department) {
      commit("SET_DEPARTMENT", department);
    },
    updateStatus({ commit }, status) {
      commit("SET_STATUS", status);
    },
    updateCurrentTab({ commit }, currentTab) {
      commit("SET_CURRENT_TAB", currentTab);
    },
    updatePage({ commit }, page) {
      commit("SET_PAGE", page);
    },
    updatePerPage({ commit }, perPage) {
      commit("SET_PER_PAGE", perPage);
    },
    setSelectedStaff({ commit }, staff) {
      commit("SET_SELECTED_STAFF", staff);
    },
  },
  getters: {
    leaves: (state) => state.leaves,
    totalRecords: (state) => state.totalRecords,
    searchState: (state) => state.searchState,
    department: (state) => state.department,
    status: (state) => state.status,
    currentTab: (state) => state.currentTab,
    page: (state) => state.page,
    perPage: (state) => state.perPage,
    selectedStaff: (state) => state.selectedStaff,
    formattedLeaves: (state) => {
      return state.leaves.map((item) => {
        let formattedItem = { ...item };
        formattedItem.leaves.formattedStartDate = moment
          .unix(item.leaves.start_date)
          .format("DD/MM/YYYY");
        formattedItem.leaves.formattedEndDate = moment
          .unix(item.leaves.end_date)
          .format("DD/MM/YYYY");
        formattedItem.leaves.formattedRequestDate = moment
          .unix(item.leaves.request_date)
          .format("DD/MM/YYYY");
        return formattedItem;
      });
    },
  },
};
