import axios from "axios";

const purchaseOrderUrl = "/v2/api/purchase-orders";

class PurchaseOrderService {
    static searchAllPurchaseOrders(urlParams) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}${urlParams}`;
                const result = await axios.get(url);
                const pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getPurchaseOrderById(purchaseOrderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}`;
                const result = await axios.get(url);
                const purchaseOrderData = result.data;
                resolve(purchaseOrderData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createPurchaseOrder(purchaseOrderData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = purchaseOrderUrl;
                const result = await axios.post(url, purchaseOrderData);
                const createdPurchaseOrder = result.data;
                resolve(createdPurchaseOrder);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updatePurchaseOrder(purchaseOrderId, updatedPurchaseOrderData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}`;
                await axios.put(url, updatedPurchaseOrderData);
                resolve(`Purchase order with ID ${purchaseOrderId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deletePurchaseOrder(purchaseOrderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}`;
                await axios.delete(url);
                resolve(`Purchase order with ID ${purchaseOrderId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }


    static createPurchaseItem(purchaseOrderId, purchaseItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}/items`;
                const result = await axios.post(url, purchaseItemData);
                const createdPurchaseItem = result.data;
                resolve(createdPurchaseItem);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updatePurchaseItem(purchaseOrderId, purchaseItemId, updatedPurchaseItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}/items/${purchaseItemId}`;
                await axios.put(url, updatedPurchaseItemData);
                resolve(`Purchase item with ID ${purchaseItemId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deletePurchaseItem(purchaseOrderId, purchaseItemId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}/items/${purchaseItemId}`;
                await axios.delete(url);
                resolve(`Purchase item with ID ${purchaseItemId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }


    static createReceivedItem(purchaseOrderId, purchaseItemId, receivedItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}/items/${purchaseItemId}/received-items`;
                const result = await axios.post(url, receivedItemData);
                const createdReceivedItem = result.data;
                resolve(createdReceivedItem);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateReceivedItem(purchaseOrderId, purchaseItemId, receivedItemId, updatedReceivedItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}/items/${purchaseItemId}/received-items/${receivedItemId}`;
                await axios.put(url, updatedReceivedItemData);
                resolve(`Received item with ID ${receivedItemId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteReceivedItem(purchaseOrderId, purchaseItemId, receivedItemId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}/items/${purchaseItemId}/received-items/${receivedItemId}`;
                await axios.delete(url);
                resolve(`Received item with ID ${receivedItemId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static async generatePurchaseOrderReport(purchaseOrderId, reportType) {
        try {
            const url = `${purchaseOrderUrl}/${purchaseOrderId}/report/${reportType}`;
            const response = await axios.post(url, {}, {responseType: 'blob'});
            return response.data;
        } catch (error) {
            throw new Error(error.response ? error.response.data : error.message);
        }
    }

    static copyPurchaseOrder(purchaseOrderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${purchaseOrderUrl}/${purchaseOrderId}/copy`;
                const response = await axios.post(url);
                resolve(response.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

}

export default PurchaseOrderService;
