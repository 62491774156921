import axios from "axios";

const datamasterUrl = "/v2/api/data-master";

class DataMasterService {
    static getDataMasters(urlP) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = [datamasterUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getDataMasterById(dataMasterId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${datamasterUrl}/${dataMasterId}`;
                const result = await axios.get(url);
                const createdDataMaster = result.data;
                resolve(createdDataMaster);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createDataMaster(datamasterData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${datamasterUrl}`;
                const result = await axios.post(url, datamasterData);
                const createdDataMaster = result.data;
                resolve(createdDataMaster);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateDataMaster(dataMasterId, updatedDataMasterData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${datamasterUrl}/${dataMasterId}`;
                await axios.put(url, updatedDataMasterData);
                resolve(`Data master record with ID ${dataMasterId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteDataMaster(dataMasterId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${datamasterUrl}/${dataMasterId}`;
                await axios.delete(url);
                resolve(`Data master record with ID ${dataMasterId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }
}

export default DataMasterService;
