import moment from "moment";
import AttendanceService from "@/services/attendanceService";

export const AttendanceStore = {
    namespaced: true,
    state: {
        tabs: {
            staff: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "first_name",
                sortOrder: "-1",
                first: 0,
            },
            guest: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 20,
                currentPage: 1,
                sortField: "first_name",
                sortOrder: "-1",
                first: 0,
            },
        },
        currentTab: "staff",
        searchText: "",
        fromDate: null,
        toDate: null,
        isLoading: false,
    },
    mutations: {
        SET_TABLE_DATA(state, { tab, data }) {
            state.tabs[tab].tableData = data.data;
            state.tabs[tab].totalRecords = data.count;
        },
        UPDATE_CURRENT_PAGE(state, { tab, page }) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_ROWS_PER_PAGE(state, { tab, rows }) {
            state.tabs[tab].rowsPerPage = rows;
        },
        UPDATE_SORT_OPTIONS(state, { tab, field, order }) {
            state.tabs[tab].sortField = field;
            state.tabs[tab].sortOrder = order;
        },
        RESET_FIRST_VALUE(state, { tab }) {
            const currentPage = state.tabs[tab].currentPage;
            const rowsPerPage = state.tabs[tab].rowsPerPage;
            state.tabs[tab].first = currentPage > 0 ? (currentPage - 1) * rowsPerPage : 0;
        },
        RESET_CURRENT_PAGE(state, { tab, page = 0 }) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_SEARCH_TEXT(state, searchText) {
            state.searchText = searchText;
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        UPDATE_FROM_DATE(state, fromDate) {
            state.fromDate = fromDate;
        },
        UPDATE_TO_DATE(state, toDate) {
            state.toDate = toDate;
        },
    },
    actions: {
        async fetchTableData({ commit, state, dispatch }) {
            
            commit("SET_LOADING", true);
            const currentTabState = state.tabs[state.currentTab];
            let params = buildParams(currentTabState, state);
            const apiEndpoint = getApiEndpoint();
            const queryString = buildQueryString(params);

            try {
                let data = await apiEndpoint(`?${queryString}`);
                data.data = formatAttendanceData(data.data);
                commit("SET_TABLE_DATA", { tab: state.currentTab, data: [] });
                commit("SET_TABLE_DATA", { tab: state.currentTab, data });
            } catch (error) {
                commit("SET_TABLE_DATA", { tab: state.currentTab, data: [] });
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
                dispatch("resetFirstValue");
            }
        },
        updateTableSettings({ commit, dispatch, state }, { rows, page }) {
            commit("UPDATE_ROWS_PER_PAGE", { tab: state.currentTab, rows });
            commit("UPDATE_CURRENT_PAGE", { tab: state.currentTab, page: page + 1 });
            dispatch("fetchTableData");
        },
        updateSortOptions({ commit, dispatch, state }, { field, order }) {
            commit("UPDATE_SORT_OPTIONS", { tab: state.currentTab, field, order });
            dispatch("fetchTableData");
        },
        updateSearchCriteria({ commit, dispatch }, { searchText, fromDate, toDate }) {
            commit("UPDATE_SEARCH_TEXT", searchText);
            commit("UPDATE_FROM_DATE", fromDate);
            commit("UPDATE_TO_DATE", toDate);
            dispatch("fetchTableData");
        },
        updateTab({ commit, dispatch }, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },
        resetFirstValue({ commit, state }) {
            commit("RESET_FIRST_VALUE", { tab: state.currentTab });
        },
        resetCurrentPage({ commit, state }, page = 0) {
            
            commit("RESET_CURRENT_PAGE", { tab: state.currentTab, page });
        },
    },
};

function buildQueryString(params) {
    return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}

function buildParams(currentTabState, state) {
    let params = {
        page: currentTabState.currentPage,
        limit: currentTabState.rowsPerPage,
        orderBy: currentTabState.sortField,
        order: currentTabState.sortOrder,
        user_type: state.currentTab,
    };

    if (state.searchText) {
        params.searchTerm = state.searchText;
    }

    if (state.fromDate) {
        params.fromDate = moment(state.fromDate).format('DD/MM/YYYY');
    }

    if (state.toDate) {
        params.toDate = moment(state.toDate).format('DD/MM/YYYY');
    }

    return params;
}

function getApiEndpoint() {
    return AttendanceService.getAttendances;
}

function formatAttendanceData(data) {
    return data.map(user => {
        // Sort the attendance array by Unix timestamps in descending order
        const sortedAttendance = user.attendance.sort((a, b) => b.date - a.date);

        // Map the sorted attendance to the desired format
        const formattedAttendance = sortedAttendance.map(att => ({
            unix_date: att.date,  // Include Unix date for sorting
            day: moment.unix(att.date).format("MMM D ddd"),
            attendance: {
                in_time: att.firstCheckIn ? moment.unix(att.firstCheckIn).format("HH:mm A") : null,
                out_time: att.lastCheckOut ? moment.unix(att.lastCheckOut).format("HH:mm A") : null,
                duration: att.duration ? `${Math.floor(att.duration / 3600)}h ${Math.floor((att.duration % 3600) / 60)}m` : null
            }
        }));

        // Return the formatted user data with attendance records
        return {
            id: user.staff_id, // Ensure a unique key
            first_name: user.first_name,
            last_name: user.last_name,
            ...Object.fromEntries(formattedAttendance.map(att => [att.day, att.attendance]))
        };
    });
}

