import axios from "axios";

const salesOrderUrl = "/v2/api/sales-orders";


class SalesOrderService {
    static searchAllSalesOrders(urlParams) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}${urlParams}`;
                const result = await axios.get(url);
                const pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getSalesOrderById(salesOrderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}`;
                const result = await axios.get(url);
                const salesOrderData = result.data;
                resolve(salesOrderData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createSalesOrder(salesOrderData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = salesOrderUrl;
                const result = await axios.post(url, salesOrderData);
                const createdSalesOrder = result.data;
                resolve(createdSalesOrder);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateSalesOrder(salesOrderId, updatedSalesOrderData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}`;
                await axios.put(url, updatedSalesOrderData);
                resolve(`Sales order with ID ${salesOrderId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static copySalesOrder(salesOrderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/copy`; // Construct the URL for copying the order
                const result = await axios.post(url);
                const copiedSalesOrder = result.data;
                resolve(copiedSalesOrder);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteSalesOrder(salesOrderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}`;
                await axios.delete(url);
                resolve(`Sales order with ID ${salesOrderId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createSalesItem(salesOrderId, salesItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/items`;
                const result = await axios.post(url, salesItemData);
                const createdSalesItem = result.data;
                resolve(createdSalesItem);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateSalesItem(salesOrderId, salesItemId, updatedSalesItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/items/${salesItemId}`;
                await axios.put(url, updatedSalesItemData);
                resolve(`Sales item with ID ${salesItemId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteSalesItem(salesOrderId, salesItemId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/items/${salesItemId}`;
                await axios.delete(url);
                resolve(`Sales item with ID ${salesItemId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Create a dispatch note for a sales order
    static createDispatchNote(salesOrderId, dispatchNoteData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes`;
                const result = await axios.post(url, dispatchNoteData);
                const createdDispatchNote = result.data;
                resolve(createdDispatchNote);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Update a dispatch note
    static updateDispatchNote(salesOrderId, dispatchNoteId, updatedDispatchNoteData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}`;
                await axios.put(url, updatedDispatchNoteData);
                resolve(`Despatch note with ID ${dispatchNoteId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Delete a dispatch note
    static deleteDispatchNote(salesOrderId, dispatchNoteId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}`;
                await axios.delete(url);
                resolve(`Despatch note with ID ${dispatchNoteId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createPackList(salesOrderId, dispatchNoteId, packListData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists`;
                const result = await axios.post(url, packListData);
                const createdPackList = result.data;
                resolve(createdPackList);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Update a pack list for a dispatch note
    static updatePackList(salesOrderId, dispatchNoteId, packListId, updatedPackListData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}`;
                await axios.put(url, updatedPackListData);
                resolve(`Pack list with ID ${packListId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Delete a pack list from a dispatch note
    static deletePackList(salesOrderId, dispatchNoteId, packListId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}`;
                await axios.delete(url);
                resolve(`Pack list with ID ${packListId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Create a pack list item in a specific pack list
    static createPackListItem(salesOrderId, dispatchNoteId, packListId, packListItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/items`;
                const result = await axios.post(url, packListItemData);
                const createdPackListItem = result.data;
                resolve(createdPackListItem);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Update a specific pack list item
    static updatePackListItem(salesOrderId, dispatchNoteId, packListId, packListItemId, updatedPackListItemData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/items/${packListItemId}`;
                await axios.put(url, updatedPackListItemData);
                resolve(`Pack list item with ID ${packListItemId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Delete a specific pack list item from a pack list
    static deletePackListItem(salesOrderId, dispatchNoteId, packListId, packListItemId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/items/${packListItemId}`;
                await axios.delete(url);
                resolve(`Pack list item with ID ${packListItemId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Bulk update multiple pack list items in a specific pack list
    static updateMultiplePackListItems(salesOrderId, dispatchNoteId, packListId, updatedItems) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/items/bulk-update`;
                await axios.put(url, updatedItems);
                resolve(`Pack list items updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

// Confirm and remove not packed items in a specific pack list
    static confirmAndRemoveNotPackedItems(salesOrderId, dispatchNoteId, packListId, packListData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/confirm-remove-not-packed`;
                await axios.put(url, packListData);
                resolve(`Confirmation and removal of not packed items completed for pack list with ID ${packListId}`);
            } catch (error) {
                reject(error.response);
            }
        });
    }


    // Add a serial number to a specific pack list item
    static addSerialNumber(salesOrderId, dispatchNoteId, packListId, packListItemId, serialNumber) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/items/${packListItemId}/serial-numbers`;
                const result = await axios.post(url, { serial_number: serialNumber });
                const addedSerialNumber = result.data;
                resolve(addedSerialNumber);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Update a specific serial number for a pack list item
    static updateSerialNumber(salesOrderId, dispatchNoteId, packListId, packListItemId, serialId, updatedSerialNumber) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/items/${packListItemId}/serial-numbers/${serialId}`;
                await axios.put(url, { serial_number: updatedSerialNumber });
                resolve(`Serial number with ID ${serialId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    // Delete a specific serial number from a pack list item
    static deleteSerialNumber(salesOrderId, dispatchNoteId, packListId, packListItemId, serialId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/items/${packListItemId}/serial-numbers/${serialId}`;
                await axios.delete(url);
                resolve(`Serial number with ID ${serialId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }


    // Bulk add serial numbers to a specific pack list item
    static bulkAddSerialNumbers(salesOrderId, dispatchNoteId, packListId, packListItemId, serialNumbers) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${salesOrderUrl}/${salesOrderId}/dispatch-notes/${dispatchNoteId}/pack-lists/${packListId}/items/${packListItemId}/serial-numbers/bulk-add`;
                const result = await axios.post(url, { serial_numbers: serialNumbers });
                const addedSerialNumbers = result.data;
                resolve(addedSerialNumbers);
            } catch (error) {
                reject(error.response);
            }
        });
    }





    // Report Services
    static async generateSalesOrderReport(salesOrderId, reportType) {
        try {
            const url = `${salesOrderUrl}/${salesOrderId}/report/${reportType}`;
            const response = await axios.post(url, {}, { responseType: 'blob' });
            return response.data;
        } catch (error) {
            throw new Error(error.response ? error.response.data : error.message);
        }
    }

    static async generateDispatchNoteReport(salesOrderId, dispatchNoteId, reportType) {
        try {
            const url = `${salesOrderUrl}/${salesOrderId}/report/${reportType}/dispatch-note/${dispatchNoteId}`;
            const response = await axios.post(url, {}, { responseType: 'blob' });
            return response.data;
        } catch (error) {
            throw new Error(error.response ? error.response.data : error.message);
        }
    }


    static async generatePackListReport(salesOrderId, dispatchNoteId, packListId, reportType) {
        try {
            const url = `${salesOrderUrl}/${salesOrderId}/report/${reportType}/dispatch-note/${dispatchNoteId}/pack-list/${packListId}`;
            const response = await axios.post(url, {}, { responseType: 'blob' });
            return response.data;
        } catch (error) {
            throw new Error(error.response ? error.response.data : error.message);
        }
    }



}

export default SalesOrderService;
