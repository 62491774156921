import ScheduleService from "@/services/scheduleService";
import moment from "moment";

export const MyScheduleStore = {
    namespaced: true,
    state: {
        tabs: {
            my_schedule: {
                customer_id: null,
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                sortField: "scheduled_date",
                sortOrder: "asc",
            },
        },
        currentTab: "my_schedule",
        searchText: "",
        selectedEngineer: null,
        fromDate: null,
        toDate: null,
        isLoading: false,
    },
    mutations: {
        SET_TABLE_DATA(state, {tab, data}) {
            state.tabs[tab].tableData = data.data;
            state.tabs[tab].totalRecords = data.count;
        },
        UPDATE_CURRENT_PAGE(state, {tab, page}) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_ROWS_PER_PAGE(state, {tab, rows}) {
            state.tabs[tab].rowsPerPage = rows;
        },
        UPDATE_SORT_OPTIONS(state, {tab, field, order}) {
            state.tabs[tab].sortField = field;
            state.tabs[tab].sortOrder = order;
        },
        UPDATE_SEARCH_TEXT(state, searchText) {
            state.searchText = searchText;
        },
        UPDATE_SELECTED_ENGINEER(state, selectedEngineer) {
            state.selectedEngineer = selectedEngineer;
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        UPDATE_FROM_DATE(state, fromDate) {
            state.fromDate = fromDate;
        },
        UPDATE_TO_DATE(state, toDate) {
            state.toDate = toDate;
        },
        UPDATE_CURRENT_CUSTOMER_ID(state, currentCustomerId) {
            state.tabs["equipment"].customer_id = currentCustomerId;
        },
    },
    actions: {
        async fetchTableData({commit, state}) {
            commit("SET_LOADING", true);

            const currentTabState = state.tabs[state.currentTab];

            let params = buildParams(currentTabState, state);

            const apiEndpoint = getApiEndpoint();
            const queryString = buildQueryString(params);

            try {
                let data = await apiEndpoint(`?${queryString}`);

                if (state.currentTab === 'my_schedule') {
                    data = processMyScheduleData(data, params.startDate, params.endDate);

                }

                commit("SET_TABLE_DATA", {tab: state.currentTab, data});
            } catch (error) {
                commit("SET_TABLE_DATA", {tab: state.currentTab, data: []});
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        updateTableSettings({commit, dispatch, state}, {rows, page}) {
            commit("UPDATE_ROWS_PER_PAGE", {tab: state.currentTab, rows});
            commit("UPDATE_CURRENT_PAGE", {tab: state.currentTab, page: page + 1});
            dispatch("fetchTableData");
        },
        updateSortOptions({commit, dispatch, state}, {field, order}) {
            commit("UPDATE_SORT_OPTIONS", {tab: state.currentTab, field, order});
            dispatch("fetchTableData");
        },
        updateSearchCriteria({commit}, {searchText, selectedEngineer, searchDate}) {
            commit("UPDATE_SEARCH_TEXT", searchText);
            commit("UPDATE_SELECTED_ENGINEER", selectedEngineer);
            commit("UPDATE_SELECTED_DATE", searchDate);
        },
        updateTab({commit, dispatch}, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },
        updateCurrentCustomerId({commit, dispatch}, currentCustomerId) {
            commit("UPDATE_CURRENT_CUSTOMER_ID", currentCustomerId);
        },
    },
};

function buildQueryString(params) {
    return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}

function buildParams(currentTabState, state) {

    let params = {
        page: currentTabState.currentPage,
        limit: currentTabState.rowsPerPage,
        orderBy: currentTabState.sortField,
        order: currentTabState.sortOrder,
        tab: state.currentTab,
    };

    if (state.searchText) {
        params.searchTerm = state.searchText;
    }

    const formattedFromDate = formatDateToUKFormat(state.fromDate);
    if (formattedFromDate && formattedFromDate !== "Invalid date") {
        params.fromDate = formattedFromDate;
    }

    const formattedToDate = formatDateToUKFormat(state.toDate);
    if (formattedToDate && formattedToDate !== "Invalid date") {
        params.toDate = formattedToDate;
    }

    if (state.selectedEngineer && state.selectedEngineer.staff_id) {
        params.engineer_id = state.selectedEngineer.staff_id;
    }

    return params;
}

function getApiEndpoint() {
    return ScheduleService.searchAllSchedules;
}

function formatDateToUKFormat(dateInput) {
    const date = typeof dateInput === 'string' ? moment(dateInput) : moment(dateInput);
    return date.format('DD/MM/YYYY');
}

function processMyScheduleData(originalData) {

    let processedData = originalData.data.map(item => {
        let scheduledTimestamp = item.scheduled_date;
        let scheduledMoment = moment.unix(scheduledTimestamp);

        let schedule_date = scheduledMoment.format('DD-MM-YYYY');
        let schedule_time = scheduledMoment.format('HH:mm');
        let schedule_day = scheduledMoment.format('dddd');

        return {
            ...item,
            schedule_date,
            schedule_time,
            schedule_day
        };
    });

    processedData.sort((a, b) => {
        let dateA = moment(a.schedule_date, 'DD-MM-YYYY');
        let dateB = moment(b.schedule_date, 'DD-MM-YYYY');

        if (dateA.isSame(dateB)) {
            let timeA = moment(a.schedule_time, 'HH:mm');
            let timeB = moment(b.schedule_time, 'HH:mm');
            return timeA - timeB;
        } else {
            return dateA - dateB;
        }
    });

    return {
        ...originalData,
        data: processedData
    };
}
