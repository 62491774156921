import axios from 'axios'

class UserService {
    // Get users
    static getUsers() {
        return new Promise(async (resolve, reject) => {
            try {
                let usersResult = await axios.get('/api/users')
                resolve(usersResult.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }
}

export default UserService