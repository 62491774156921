<template>
  <svg
      class="stroke-current h-5 w-5 mr-2"
      fill="none"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      viewBox="0 0 24 24"
  >
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path
        d="M8 9h8M8 13h6M14 18h-1l-5 3v-3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4.5M19 22v.01M19 19a2.003 2.003 0 0 0 .914-3.782 1.98 1.98 0 0 0-2.414.483"/>
  </svg>
</template>

<script>
export default {
  name: 'FaqIcon',
  props: {
    height: {
      type: [Number, String],
      default: 24
    },
    width: {
      type: [Number, String],
      default: 24
    },
    fillColor: {
      type: String,
      default: "#000000"
    }, color: {
      type: String,
      default: "#ffffff"
    }
  }
}
</script>
  