import axios from "axios";

const loanEquipmentUrl = "/v2/api/equipment/loan/";

class LoanEquipmentService {
  static getLoanEquipment(urlP) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = [loanEquipmentUrl].join("");
        url = url + urlP;
        let result = await axios.get(url);
        result.data.data.forEach(e => {
          if (e.loan_equipment)
            e.loan_equipment.status = e.loan_equipment.status === "AT CUSTOMER" ?
              e.loan_equipment.current_location.name : e.loan_equipment.status
          if (e.previous_loan_equipment)
            e.previous_loan_equipment.status = e.previous_loan_equipment.status === "AT CUSTOMER" ?
              e.previous_loan_equipment.current_location.name : e.previous_loan_equipment.status
        });
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static loadStatusList() {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${loanEquipmentUrl}master/status`;
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static addLocation(equipmentId, newLocation) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${loanEquipmentUrl}${equipmentId}/location/add`;
        let result = await axios.post(url, newLocation);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}

export default LoanEquipmentService;
