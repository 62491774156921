import axios from "axios";

const staffUrl = "/v2/api/staff";

class HolidayService {
  // Search Staff
  static searchStaff(searchTerm, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        let url = [
          staffUrl,
          `?searchTerm=${searchTerm}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
          `&select=${pagingOpts.select || ""}`,
          `&staff_email=${pagingOpts.staff_email || ""}`,
        ].join("");

        let result = await axios.get(url);

        let pagingData = result.data;

        resolve(pagingData);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  // Create Leave
  static createStaffLeave(staffId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${staffUrl}/${staffId}/leave`;
        let result = await axios.post(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  //Search leaves
  static searchAllLeaves(searchTerm, pagingOpts, staffId) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        let url = [
          staffUrl,
          `?searchTerm=${searchTerm}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
          `&name=${pagingOpts.name || "upcoming"}`,
          `&staff_id=${pagingOpts.staff_id || ""}`,
        ].join("");

        let result = await axios.get(url);

        let pagingData = result.data;

        resolve(pagingData);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  //Search leaves
  static searchStaffLeaves(searchTerm, pagingOpts, staffEmail) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        let url = [
          staffUrl,
          `?searchTerm=${searchTerm}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
          `&name=${pagingOpts.name || "upcoming"}`,
          `&staff_id=${pagingOpts.staff_id || ""}`,
          `&staff_email=${pagingOpts.staff_email || ""}`,
        ].join("");

        let result = await axios.get(url);

        let pagingData = result.data;

        resolve(pagingData);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  // Remove staff leave
  static deleteLeave(staffId, id) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${staffUrl}/${staffId}/leave/${id}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update staff Leave
  static updateLeave(staffId, id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${staffUrl}/${staffId}/leave/${id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Create Leave Balance
  static createStaffLeaveBalance(staffId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${staffUrl}/${staffId}/leave/balance`;
        let result = await axios.post(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Leave Balance
  static updateStaffLeaveBalance(staffId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${staffUrl}/${staffId}/leave/balance`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Staff leave overview
  static getLeaveOverview(search, staffId) {
    return new Promise(async (resolve, reject) => {
      try {
        search = search || {};
        let queryUrl = [
          `${staffUrl}/${staffId}/leave/overview`,
          `?staff_email=${search.staff_email || ""}`,
          `&department=${search.department || ""}`,
        ].join("");

        let result = await axios.get(queryUrl);
        resolve(result);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  // Staff leave summaries
  static getLeaveSummary(search, staffId) {
    return new Promise(async (resolve, reject) => {
      try {
        search = search || {};

        let queryUrl = [
          `${staffUrl}/${staffId}/leave/summaries`,
          `?monthsPrior=${search.months_prior || 1}`,
          `&monthsPost=${search.months_post || 3}`,
          search.department ? `&department=${search.department}` : "",
          search.status ? `&status=${search.status}` : "",
          search.searchStaffId ? `&searchStaffId=${search.searchStaffId}` : "",
        ].join("");

        let result = await axios.get(queryUrl);
        resolve(result);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }
}

export default HolidayService;
