import moment from "moment";
import jobSheetService from "@/services/jobSheetService";
import scheduleService from "@/services/scheduleService";

export const JobSheetStore = {
    namespaced: true,
    state: {
        tabs: {
            external: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchText: "",
                fromDate: null,
                toDate: null,
                is_pending: false,
                is_completed: false,
                sortField: "next_calibration",
                sortOrder: "asc",
            },
            internal: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                searchText: "",
                fromDate: null,
                toDate: null,
                is_pending: false,
                is_completed: false,
                sortField: "next_calibration",
                sortOrder: "asc",
            },
        },
        currentTab: "external",
        searchText: "brad",
        selectedEngineer: null,
        searchDate: null,
        isLoading: false,
    },
    mutations: {
        SET_TABLE_DATA(state, {tab, data}) {
            state.tabs[tab].tableData = data.data;
            state.tabs[tab].totalRecords = data.count;
        },
        UPDATE_CURRENT_PAGE(state, {tab, page}) {
            state.tabs[tab].currentPage = page;
        },
        UPDATE_ROWS_PER_PAGE(state, {tab, rows}) {
            state.tabs[tab].rowsPerPage = rows;
        },
        UPDATE_SORT_OPTIONS(state, {tab, field, order}) {
            state.tabs[tab].sortField = field;
            state.tabs[tab].sortOrder = order;
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        UPDATE_FROM_DATE(state, {tab, fromDate}) {

            state.tabs[tab].fromDate = fromDate;
        },
        UPDATE_TO_DATE(state, {tab, toDate}) {
            state.tabs[tab].toDate = toDate;
        },
        UPDATE_IS_PENDING(state, {tab, isPending}) {
            state.tabs[tab].is_pending = isPending;
        },
        UPDATE_IS_COMPLETED(state, {tab, isCompleted}) {
            state.tabs[tab].is_completed = isCompleted;
        },
        UPDATE_SEARCH_TEXT(state, {tab, searchText}) {
            state.tabs[tab].searchText = searchText;
        },
    },
    actions: {
        async fetchTableData({commit, state}) {
            commit("SET_LOADING", true);

            const currentTabState = state.tabs[state.currentTab];

            let params = buildParams(currentTabState, state);

            const apiEndpoint = getApiEndpoint();
            const queryString = buildQueryString(params);

            try {
                let data = await apiEndpoint(`?${queryString}`);
                commit("SET_TABLE_DATA", {tab: state.currentTab, data});
            } catch (error) {
                commit("SET_TABLE_DATA", {tab: state.currentTab, data: []});
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        updateTableSettings({commit, dispatch, state}, {rows, page}) {
            commit("UPDATE_ROWS_PER_PAGE", {tab: state.currentTab, rows});
            commit("UPDATE_CURRENT_PAGE", {tab: state.currentTab, page: page + 1});
            dispatch("fetchTableData");
        },
        updateSortOptions({commit, dispatch, state}, {field, order}) {
            commit("UPDATE_SORT_OPTIONS", {tab: state.currentTab, field, order});
            dispatch("fetchTableData");
        },
        updateSearchCriteria({commit, state}, {
            searchText,
            fromDate,
            toDate,
            isPending,
            isCompleted
        }) {
            const tab = state.currentTab;
            commit("UPDATE_SEARCH_TEXT", {tab, searchText});
            commit("UPDATE_FROM_DATE", {tab, fromDate});
            commit("UPDATE_TO_DATE", {tab, toDate});
            commit("UPDATE_IS_PENDING", {tab, isPending});
            commit("UPDATE_IS_COMPLETED", {tab, isCompleted});
        },
        updateTab({commit, dispatch}, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },

    },
};

function buildQueryString(params) {
    return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}

function buildParams(currentTabState, state) {
    let params = {
        page: currentTabState.currentPage,
        limit: currentTabState.rowsPerPage,
        orderBy: currentTabState.sortField,
        order: currentTabState.sortOrder,
        type: state.currentTab,
    };

    if (currentTabState.searchText) {
        params.searchTerm = currentTabState.searchText;
    }

    const formattedFromDate = formatDateToUKFormat(currentTabState.fromDate);
    if (formattedFromDate && formattedFromDate !== "Invalid date") {
        params.fromDate = formattedFromDate;
    }

    const formattedToDate = formatDateToUKFormat(currentTabState.toDate);
    if (formattedToDate && formattedToDate !== "Invalid date") {
        params.toDate = formattedToDate;
    }

    if (currentTabState.selectedEngineer && currentTabState.selectedEngineer.staff_id) {
        params.engineer_id = currentTabState.selectedEngineer.staff_id;
    }
    if (currentTabState.is_pending) {
        params.is_pending = currentTabState.is_pending;
    }
    if (currentTabState.is_completed) {
        params.is_completed = currentTabState.is_completed;
    }

    return params;
}

function formatDateToUKFormat(dateInput) {
    const date = typeof dateInput === 'string' ? moment(dateInput) : moment(dateInput);
    return date.format('DD/MM/YYYY');
}


function getApiEndpoint() {
    return jobSheetService.getJobSheets;
}


