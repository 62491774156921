import axios from "axios";
import {PRODUCT_CODE_JSON_URL} from "@/utils/constants";

const productsUrl = "/api/products";
const productsUrlV2 = "/v2/api/products";
const suppliersUrl = "/api/suppliers";

class ProductService {
    static getProductCodeDataFromConfig() {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await fetch(PRODUCT_CODE_JSON_URL);
                let json = await result.json();
                resolve(json);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Get Products
    static getProducts(searchTerm) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = searchTerm
                    ? `${productsUrl}?searchTerm=${searchTerm}`
                    : `${productsUrl}/?condensed=true`;
                let result = await axios.get(url);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Get Products
    static getProductsByV2(searchTerm) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = searchTerm
                    ? `${productsUrlV2}?searchTerm=${searchTerm}&noPaging=true`
                    : `${productsUrlV2}/?condensed=true&noPaging=true`;
                let result = await axios.get(url);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Get Product by Code
    static getProductByCodeV1(productCode) {
        return new Promise(async (resolve, reject) => {
            try {
                let productUrl = `${productsUrl}/${productCode}`;
                let result = await axios.get(productUrl);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Create Product
    static createProduct(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.post(productsUrl, data);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Update Product
    static updateProduct(productCode, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let productUrl = `${productsUrl}/${productCode}`;
                let result = await axios.put(productUrl, data);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Archive Product
    static archiveProduct(productCode) {
        return new Promise(async (resolve, reject) => {
            try {
                let productUrl = `${productsUrl}/${productCode}/archive`;
                let result = await axios.put(productUrl);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Get Suppliers
    static getSuppliers() {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${suppliersUrl}/?condensed=true`;
                let result = await axios.get(url);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Add or Update Line Item
    static addUpdateLineItem(productCode, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let lineItemUrl = `${productsUrl}/${productCode}/lineitems`;
                let result = await axios.put(lineItemUrl, data);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Delete Line Item
    static deleteLineItem(productCode, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let lineItemUrl = `${productsUrl}/${productCode}/lineitems/${data.product_code}`;
                let result = await axios.delete(lineItemUrl, data);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Create Product Attachment
    static createProductAttachment(productCode, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentUrl = `${productsUrl}/${productCode}/attachments/`;
                let result = await axios.post(attachmentUrl, data);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Get Product Attachment
    static getProductAttachmentById(attachmentId) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentUrl = `/api/files/${attachmentId}`;
                let result = await axios.get(attachmentUrl);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Update Product Attachment
    static updateProductAttachment(productCode, attachmentId, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentUrl = `${productsUrl}/${productCode}/attachments/${attachmentId}`;
                let result = await axios.put(attachmentUrl, data);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Delete Product Attachment
    static deleteProductAttachment(productCode, attachmentId) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentUrl = `${productsUrl}/${productCode}/attachments/${attachmentId}`;
                let result = await axios.delete(attachmentUrl);
                resolve(result);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    static getProductById(productId) {
        return new Promise(async (resolve, reject) => {
            try {
                let productUrl = `${productsUrlV2}/${productId}`;
                let result = await axios.get(productUrl);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    static getProductByCode(productCode) {
        return new Promise(async (resolve, reject) => {
            try {
                let productUrl = `${productsUrlV2}/by-code/${productCode}`;
                let result = await axios.get(productUrl);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Verify Product Price
    static verifyProductPrice(productCode) {
        return new Promise(async (resolve, reject) => {
            try {
                let productUrl = `${productsUrl}/${productCode}/verify`;
                let result = await axios.put(productUrl);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Create / Update Product Price band
    static createUpdatePriceBand(productCode, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let productUrl = `${productsUrl}/${productCode}/pricebands`;
                let result = await axios.put(productUrl, data);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Delete Product Price band
    static deletePriceBand(productCode, priceBandCode) {
        return new Promise(async (resolve, reject) => {
            try {
                let productUrl = `${productsUrl}/${productCode}/pricebands/${priceBandCode}`;
                let result = await axios.delete(productUrl);
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // LookupProduct
    static lookupProduct(searchTerm) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.get(
                    `/v2/api/products?searchTerm=${encodeURIComponent(
                        searchTerm
                    )}&select=product_id,name,product_code&limit=100`
                );
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // LookupProduct by productCode
    static lookupProductByProductCode(productCode) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.get(
                    `/v2/api/products?productCode=${productCode}&select=product_id,name,product_code&limit=100`
                );
                resolve(result.data);
            } catch (err) {
                reject(err.response);
            }
        });
    }

    // Create Service Template
    static createProductServiceTemplate(productId, serviceTemplateData) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${productsUrlV2}/${productId}/service-templates`;
                let result = await axios.post(url, serviceTemplateData);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Update Service Template
    static updateProductServiceTemplate(productId, templateId, serviceTemplateData) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${productsUrlV2}/${productId}/service-templates/${templateId}`;
                let result = await axios.put(url, serviceTemplateData);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Remove Service Template
    static deleteServiceTemplate(productId, templateId) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${productsUrlV2}/${productId}/service-templates/${templateId}`;
                let result = await axios.delete(url);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    static createProductCertificateType(productId, certificateTypeData) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${productsUrlV2}/${productId}/certificate-types`;
                let result = await axios.post(url, certificateTypeData);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Update Certificate Type
    static updateProductCertificateType(productId, certificateTypeId, certificateTypeData) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${productsUrlV2}/${productId}/certificate-types/${certificateTypeId}`;
                let result = await axios.put(url, certificateTypeData);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    // Remove Certificate Type
    static deleteCertificateType(productId, certificateTypeId) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${productsUrlV2}/${productId}/certificate-types/${certificateTypeId}`;
                let result = await axios.delete(url);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }

    static bulkUpdateCertificateTypes(productId, certificateTypeData) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${productsUrlV2}/${productId}/certificate-types/bulk`;
                let result = await axios.post(url, certificateTypeData);
                resolve(result);
            } catch (err) {
                console.error(err);
                reject(err.response);
            }
        });
    }
}

export default ProductService;
