import axios from "axios";

const fleetUrl = "/v2/api/fleet";

class FleetService {
  static getFleets() {
    return new Promise(async (resolve, reject) => {
      try {
        let url = [fleetUrl].join("");

        let result = await axios.get(url);
        let pagingData = result.data;
        resolve(pagingData);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Search Contact
  static searchFleet(searchTerm, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        let url = [
          fleetUrl,
          `?searchTerm=${searchTerm}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
        ].join("");

        let result = await axios.get(url);

        let pagingData = result.data;

        resolve(pagingData);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  // Get Fleet by Id
  static getFleetById(fleetId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}?expand=agent`;
        let result = await axios.get(url);

        if (result.data) {
          if (result.data.tax_expiry) {
            result.data.tax_expiry = new Date(result.data.tax_expiry);
          }
          if (result.data.purchase_date) {
            result.data.purchase_date = new Date(result.data.purchase_date);
          }
        }

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Create Fleet Vehicle
  static createFleet(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(fleetUrl, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Fleet Vehicle
  static updateFleet(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${data.fleet_id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  //Remove Fleet

  static deleteVehicle(fleetId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }
  // Create Assignment
  static createFleetAssignment(fleetId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/assignment`;
        let result = await axios.post(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Assignment
  static updateFleetAssignment(fleetId, id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/assignment/${id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Remove Assignment
  static deleteAssignment(fleetId, id) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/assignment/${id}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Create MOT
  static createFleetMOT(fleetId, motData) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/mot`;
        let result = await axios.post(url, motData);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update MOT
  static updateFleetMOT(fleetId, motId, motData) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/mot/${motId}`;
        let result = await axios.put(url, motData);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Remove MOT
  static deleteMOT(fleetId, motId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/mot/${motId}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Create Insurance
  static createFleetInsurance(fleetId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/insurance`;
        let result = await axios.post(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Insurance
  static updateFleetInsurance(fleetId, id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/insurance/${id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Remove Insurance
  static deleteInsurance(fleetId, id) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/insurance/${id}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Create Breakdown
  static createFleetBreakdown(fleetId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/breakdown`;
        let result = await axios.post(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Breakdown
  static updateFleetBreakdown(fleetId, id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/breakdown/${id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Remove Breakdown
  static deleteBreakdown(fleetId, id) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/breakdown/${id}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Create Service
  static createFleetService(fleetId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/service`;
        let result = await axios.post(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Service
  static updateFleetService(fleetId, id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/service/${id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Remove Service
  static deleteService(fleetId, id) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/service/${id}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Uploads the document
  static createVehicleDocument(fleetId, document) {
    return new Promise(async (resolve, reject) => {
      try {
        let docToUpload = {
          filename: document.file[0].name,
          content_type: document.file[0].type,
          visibility: document.visibility,
          category: document.category,
          name: document.name,
          description: document.description,
          document_number: document.document_number,
          is_published: true,
        };
        console.group("[DocumentService] uploadDocument");
        console.log("File is:", document.file[0]);
        console.log("Document is:", docToUpload);
        console.groupEnd();
        let result = await axios.post(
          `${fleetUrl}/${fleetId}/documents`,
          docToUpload,
        );
        console.log(result);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Document by Id
  static getVehicleDocuments(fleetId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(`${fleetUrl}/${fleetId}/documents`);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updateVehicleDocument(fleetId, documentId, body) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/documents/${documentId}`;
        let result = await axios.put(url, body);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deleteVehicleDocumentById(fleetId, documentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/documents/${documentId}`;
        let result = await axios.delete(url);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Document by Id
  static getDocumentById(fleetId, documentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${fleetUrl}/${fleetId}/documents/${documentId}`,
        );

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Create Registered Keeper
  static createFleetRegisteredKeeper(fleetId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/registeredkeeper`;
        let result = await axios.post(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Service
  static updateFleetRegisteredKeeper(fleetId, id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/registeredkeeper/${id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Remove Service
  static deleteRegisteredKeeper(fleetId, id) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${fleetUrl}/${fleetId}/registeredkeeper/${id}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }
}

export default FleetService;
