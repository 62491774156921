import axios from "axios";

const commentsUrl = "/v2/api/comments";

class CommentService {
  static getCommentByResourceIdAndTypeId(resourceId, resourceType) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${commentsUrl}?resourceType=${resourceType}&resourceId=${resourceId}`
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static deleteComment(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.delete(`${commentsUrl}/${data.comment_id}`);
        resolve(result.data);
      } catch (err) {
        reject(err.response);
      }
    });
  }

  static updateComment(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${commentsUrl}/${data.comment_id}`, data);
        resolve(result.data);
      } catch (err) {
        reject(err.response);
      }
    });
  }
  // Create Comment
  static createComment(resourceId, resourceType, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(commentsUrl, {
          ...data,
          resource_id: resourceId,
          resource_type: resourceType,
        });
        resolve(result.data);
      } catch (err) {
        reject(err.response);
      }
    });
  }

  static updatePinState(commentId, pinState) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${commentsUrl}/${commentId}`, {
          is_pinned: pinState,
        });
        resolve(result.data);
      } catch (err) {
        reject(err.response);
      }
    });
  }
}

export default CommentService;
