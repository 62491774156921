import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import {leavesStore} from "./store/modules/holiday/leaves";
import {GoodsInStore} from "./store/modules/goods-in/goodsInStore";
import createGenericStore from "./store/GenericStore"
import LoanEquipmentService from './services/loanEquipmentService';
import {ScheduleStore} from "@/store/modules/schedule/scheduleStore";
import {MyScheduleStore} from "@/store/modules/my-schedule/myScheduleStore";
import {ScheduleManageStore} from "@/store/modules/schedule/scheduleManageStore";
import {JobSheetStore} from "@/store/modules/job-sheet/jobSheetStore";
import {RepairStore} from "@/store/modules/repair/repairStore";
import {CertificateStore} from "@/store/modules/certificate/certificateStore";
import {PurchaseStore} from "@/store/modules/purchase/purchaseStore";
import {AttendanceStore} from "@/store/modules/attendance/attendanceStore";
import {SalesStore} from "@/store/modules/sales/salesStore";

export const LoanEquipmentStore = createGenericStore("product_code", "all", (url) => {
    return LoanEquipmentService.getLoanEquipment(url);
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userAccessToken: null,
        userIdToken: null,
        userPermissions: [],
        user: null,
        userEmail: "",
        userId: "",
        labelPrinter: "",
        serviceTicketOptions: {
            showClosed: false,
        },
        enquirySearchOptions: {
            notes: false,
            actions: false,
        },
        openSortOptions: {
            field: "next_follow_up",
            type: "asc",
        },
        productSortOptions: {
            field: "product_code",
            type: "asc",
        },
        openFilterOptions: {
            status_detail: "",
            default_agent_name: "",
            product_enquiry: "",
        },
        productFilterOptions: {
            category: "",
            type: "",
        },
        contactPaging: {
            page: 1,
            limit: 50,
        },
        contactSearchPaging: {
            page: 1,
            limit: 50,
        },
        formDirty: false,
        navPrevented: false,
        navPreventedTo: null,
        enquiriesSearchTerm: "",
        contactSearchTerm: "",
        productSearchTerm: "",
        fleetSearchTerm: "",
        staffSearchTerm: "",
        leaveSearchTerm: "",
        ticketSearchTerm: "",
        equipmentSearchTerm: "",
        lastSelectedEnquiry: "",
        lastSelectedProduct: "",
        lastSelectedContact: "",
        lastSelectedTransaction: "",
        lastSelectedFleet: "",
        lastSelectedStaff: "",
        lastSelectedLeave: "",
        incidentSearchTerm: "",
        lastSelectedIncident: "",
    },
    getters: {
        isFormDirty: (state) => {
            return state.formDirty;
        },
    },
    mutations: {
        setUserAccessToken(state, accessToken) {
            state.userAccessToken = accessToken;
        },
        setUserIdToken(state, idToken) {
            state.userIdToken = idToken;
        },
        storeUserPermissions(state, permissions) {
            state.userPermissions = permissions;
        },
        storeUserEmail(state, email) {
            state.userEmail = email;
        },
        storeUser(state, user) {
            state.user = user;
        },
        storeUserId(state, userId) {
            state.userId = userId;
        },
        storeLabelPrinter(state, labelPrinter) {
            state.labelPrinter = labelPrinter;
        },
        storeUserRoles(state, roles) {
            state.userRoles = roles;
        },
        setEnquirySearchOptions(state, searchOptions) {
            state.enquirySearchOptions = searchOptions;
        },
        setServiceTicketOptions(state, options) {
            state.serviceTicketOptions = options;
        },
        setOpenSortOptions(state, sortOptions) {
            state.openSortOptions = sortOptions;
        },
        setProductSortOptions(state, sortOptions) {
            state.productSortOptions = sortOptions;
        },
        setOpenFilterOptions(state, filterOptions) {
            state.openFilterOptions = filterOptions;
        },
        setProductFilterOptions(state, filterOptions) {
            state.productFilterOptions = filterOptions;
        },
        setFormDirty(state) {
            state.formDirty = true;
        },
        setFormClean(state) {
            state.formDirty = false;
        },
        setNavPrevented(state, option) {
            state.navPrevented = option;
        },
        setNavPreventedTo(state, destination) {
            state.navPreventedTo = destination;
        },
        setEnquiriesSearchTerm(state, searchTerm) {
            state.enquiriesSearchTerm = searchTerm;
        },
        setProductSearchTerm(state, searchTerm) {
            state.productSearchTerm = searchTerm;
        },
        setContactSearchTerm(state, searchTerm) {
            state.contactSearchTerm = searchTerm;
        },
        setFleetSearchTerm(state, searchTerm) {
            state.fleetSearchTerm = searchTerm;
        },
        setStaffSearchTerm(state, searchTerm) {
            state.staffSearchTerm = searchTerm;
        },
        setLeaveSearchTerm(state, searchTerm) {
            state.staffSearchTerm = searchTerm;
        },
        setTicketSearchTerm(state, searchTerm) {
            state.leaveSearchTerm = searchTerm;
        },
        setFaqSearchTerm(state, searchTerm) {
            state.faqSearchTerm = searchTerm;
        },
        setEquipmentSearchTerm(state, searchTerm) {
            state.equipmentSearchTerm = searchTerm;
        },
        setLastSelectedEnquiry(state, row) {
            state.lastSelectedEnquiry = row;
        },
        setLastSelectedProduct(state, row) {
            state.lastSelectedProduct = row;
        },
        setLastSelectedContact(state, row) {
            state.lastSelectedContact = row;
        },
        setLastSelectedTransaction(state, row) {
            state.lastSelectedTransaction = row;
        },
        setLastSelectedFleet(state, row) {
            state.lastSelectedFleet = row;
        },
        setLastSelectedStaff(state, row) {
            state.lastSelectedStaff = row;
        },
        setLastSelectedLeave(state, row) {
            state.lastSelectedLeave = row;
        },
        setIncidentSearchTerm(state, searchTerm) {
            state.incidentSearchTerm = searchTerm;
        },
        setLastSelectedIncident(state, row) {
            state.lastSelectedIncident = row;
        },
        setContactPaging(state, pagingOptions) {
            state.contactPaging = pagingOptions;
        },
        setContactSearchPaging(state, pagingOptions) {
            state.contactSearchPaging = pagingOptions;
        },
    },
    actions: {
        setUserAccessToken(context, accessToken) {
            context.commit("setUserAccessToken", accessToken);
        },
        setUserIdToken(context, idToken) {
            context.commit("setUserIdToken", idToken);
        },
        storeUserPermissions(context, permissions) {
            context.commit("storeUserPermissions", permissions);
        },
        storeUserEmail(context, email) {
            context.commit("storeUserEmail", email);
        },
        storeUser(context, user) {
            context.commit("storeUser", user);
        },
        storeUserId(context, userId) {
            context.commit("storeUserId", userId);
        },
        storeLabelPrinter(context, labelPrinter) {
            context.commit("storeLabelPrinter", labelPrinter);
        },
        storeUserRoles(context, roles) {
            context.commit("storeUserRoles", roles);
        },
        setEnquirySearchOptions(context, searchOptions) {
            context.commit("setEnquirySearchOptions", searchOptions);
        },
        setServiceTicketOptions(context, options) {
            context.commit("setServiceTicketOptions", options);
        },
        setOpenSortOptions(context, sortOptions) {
            context.commit("setOpenSortOptions", sortOptions);
        },
        setProductSortOptions(context, sortOptions) {
            context.commit("setProductSortOptions", sortOptions);
        },
        setOpenFilterOptions(context, filterOptions) {
            context.commit("setOpenFilterOptions", filterOptions);
        },
        setProductFilterOptions(context, filterOptions) {
            context.commit("setProductFilterOptions", filterOptions);
        },
        setFormDirty(context) {
            context.commit("setFormDirty");
        },
        setFormClean(context) {
            context.commit("setFormClean");
        },
        setNavPrevented(context) {
            context.commit("setNavPrevented", true);
        },
        clearNavPrevented(context) {
            context.commit("setNavPrevented", false);
        },
        setNavPreventedTo(context, destination) {
            context.commit("setNavPreventedTo", destination);
        },
        setEnquiriesSearchTerm(context, searchTerm) {
            context.commit("setEnquiriesSearchTerm", searchTerm);
        },
        setProductSearchTerm(context, searchTerm) {
            context.commit("setProductSearchTerm", searchTerm);
        },
        setContactSearchTerm(context, searchTerm) {
            context.commit("setContactSearchTerm", searchTerm);
        },
        setFleetSearchTerm(context, searchTerm) {
            context.commit("setFleetSearchTerm", searchTerm);
        },
        setStaffSearchTerm(context, searchTerm) {
            context.commit("setStaffSearchTerm", searchTerm);
        },
        setLeaveSearchTerm(context, searchTerm) {
            context.commit("setLeaveSearchTerm", searchTerm);
        },
        setTicketSearchTerm(context, searchTerm) {
            context.commit("setTicketSearchTerm", searchTerm);
        },
        setFaqSearchTerm(context, searchTerm) {
            context.commit("setFaqSearchTerm", searchTerm);
        },
        setEquipmentSearchTerm(context, searchTerm) {
            context.commit("setEquipmentSearchTerm", searchTerm);
        },
        setLastSelectedEnquiry(context, row) {
            context.commit("setLastSelectedEnquiry", row);
        },
        setLastSelectedProduct(context, row) {
            context.commit("setLastSelectedProduct", row);
        },
        setLastSelectedContact(context, row) {
            context.commit("setLastSelectedContact", row);
        },
        setLastSelectedTransaction(context, row) {
            context.commit("setLastSelectedTransaction", row);
        },
        setLastSelectedFleet(context, row) {
            context.commit("setLastSelectedFleet", row);
        },
        setLastSelectedStaff(context, row) {
            context.commit("setLastSelectedStaff", row);
        },
        setLastSelectedLeave(context, row) {
            context.commit("setLastSelectedLeave", row);
        },
        setIncidentSearchTerm(context, searchTerm) {
            context.commit("setIncidentSearchTerm", searchTerm);
        },
        setLastSelectedIncident(context, row) {
            context.commit("setLastSelectedIncident", row);
        },
        setContactPaging(context, pagingOptions) {
            context.commit("setContactPaging", pagingOptions);
        },
        setContactSearchPaging(context, pagingOptions) {
            context.commit("setContactSearchPaging", pagingOptions);
        },
    },
    modules: {
        goodsIn: GoodsInStore,
        leaves: leavesStore,
        loanEquipment: LoanEquipmentStore,
        schedule: ScheduleStore,
        scheduleManage: ScheduleManageStore,
        jobSheet: JobSheetStore,
        mySchedule: MyScheduleStore,
        repair: RepairStore,
        certificate: CertificateStore,
        purchase: PurchaseStore,
        attendance: AttendanceStore,
        sales: SalesStore,
    },
    plugins: [createPersistedState()],
});
