import axios from "axios";

const repairUrl = "/v2/api/repair";

class RepairService {
    static getRepairs(urlP) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = [repairUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getRepairById(repairId) {

        return new Promise(async (resolve, reject) => {
            try {
                const url = `${repairUrl}/${repairId}`;
                const result = await axios.get(url);
                const repairData = result.data;
                resolve(repairData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createRepair(repairData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${repairUrl}`;
                const result = await axios.post(url, repairData);
                const createdRepair = result.data;
                resolve(createdRepair);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateRepair(repairId, updatedRepairData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${repairUrl}/${repairId}`;
                await axios.put(url, updatedRepairData);
                resolve(`Repair record with ID ${repairId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteRepair(repairId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${repairUrl}/${repairId}`;
                await axios.delete(url);
                resolve(`Repair record with ID ${repairId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }
}

export default RepairService;
