import axios from "axios";
import GoodsInService from "@/services/goodsInService";

export const GoodsInStore = {
    namespaced: true,
    state: {
        tabs: {
            not_confirmed: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                sortField: "created_at",
                sortOrder: "desc",
            },
            confirmed: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                sortField: "created_at",
                sortOrder: "desc",
            },
            search: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                sortField: "created_at",
                sortOrder: "desc",
            },
        },
        currentTab: "not_confirmed",
        searchText: "",
        selectedCategory: null,
        isLoading: false,
    },
    mutations: {
        SET_TABLE_DATA(state, {tab, data}) {
            state.tabs[tab].tableData = data.data;
            state.tabs[tab].totalRecords = data.count;
        },
        UPDATE_CURRENT_PAGE(state, {tab, page}) {

            state.tabs[tab].currentPage = page;
        },
        UPDATE_ROWS_PER_PAGE(state, {tab, rows}) {

            state.tabs[tab].rowsPerPage = rows;
        },
        UPDATE_SORT_OPTIONS(state, {tab, field, order}) {
            state.tabs[tab].sortField = field;
            state.tabs[tab].sortOrder = order;
        },
        UPDATE_SEARCH_TEXT(state, searchText) {
            state.searchText = searchText;
        },
        UPDATE_SELECTED_CATEGORY(state, selectedCategory) {
            state.selectedCategory = selectedCategory;
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
    },
    actions: {
        async fetchTableData({commit, state}) {
            commit("SET_LOADING", true);
            const currentTabState = state.tabs[state.currentTab];
            let params = {
                page: currentTabState.currentPage,
                limit: currentTabState.rowsPerPage,
                orderBy: currentTabState.sortField,
                order: currentTabState.sortOrder,
                tab: state.currentTab,
            };
            if (state.currentTab === "search") {
                params = {
                    ...params,
                    searchTerm: state.searchText,
                    category: state.selectedCategory,
                };
            }

            const queryString = Object.entries(params)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join("&");

            try {
                const data = await GoodsInService.getGoodsIn(`?${queryString}`);
                commit("SET_TABLE_DATA", {tab: state.currentTab, data});
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
            }
        },
        updateTableSettings({commit, dispatch, state}, {rows, page}) {
            commit("UPDATE_ROWS_PER_PAGE", {tab: state.currentTab, rows});
            commit("UPDATE_CURRENT_PAGE", {tab: state.currentTab, page: page + 1});
            dispatch("fetchTableData");
        },
        updateSortOptions({commit, dispatch, state}, {field, order}) {
            commit("UPDATE_SORT_OPTIONS", {tab: state.currentTab, field, order});
            dispatch("fetchTableData");
        },
        updateSearchCriteria({commit}, {searchText, selectedCategory}) {
            commit("UPDATE_SEARCH_TEXT", searchText);
            commit("UPDATE_SELECTED_CATEGORY", selectedCategory);
        },
        updateTab({commit, dispatch}, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },
    },
};
