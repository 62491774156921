import axios from "axios";

const equipmentUrl = "/v2/api/equipment";

class EquipmentService {
    static getEquipmentItemById(equipmentId) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.get(`${equipmentUrl}/${equipmentId}`);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getEquipmentItemBySerialNumber(serialNumber) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.get(`${equipmentUrl}/serial-number/${serialNumber}`);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getEquipment(options) {
        return new Promise(async (resolve, reject) => {
            try {
                options = options || {};
                console.log(options);

                let url = [
                    equipmentUrl,
                    `?page=${options.currentPage || 1}`,
                    `&limit=${options.pageSize || 50}`,
                    `&orderBy=${options.field || "created_at"}`,
                    `&order=${options.isAsc ? "asc" : "desc"}`,
                    `&expand=owner`,
                ];

                Object.entries(options.filterOptions).map(([key, val]) => {
                    url.push(`&${key}=${val}`);
                });

                url = url.join("");

                let result = await axios.get(url);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static searchEquipment(searchTerm, options) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = [
                    equipmentUrl,
                    `?page=${options.currentPage || 1}`,
                    `&limit=${options.pageSize || 50}`,
                    `&orderBy=${options.field || "created_at"}`,
                    `&order=${options.isAsc ? "asc" : "desc"}`,
                    `&searchTerm=${searchTerm}`,
                    `&expand=owner`,
                ];

                Object.entries(options.filterOptions).map(([key, val]) => {
                    url.push(`&${key}=${val}`);
                });

                url = url.join("");

                let result = await axios.get(url);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateLoanStatus(equipmentId, isLoanUnit) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.patch(`${equipmentUrl}/${equipmentId}/update-loan-status`, {isLoanUnit: isLoanUnit});
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateExpiryDates(equipmentId, expiryDates) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.put(`${equipmentUrl}/${equipmentId}/expiry-dates`, expiryDates);
                resolve(result.data);
            } catch (error) {
                reject(error.response);
            }
        });
    }
}

export default EquipmentService;
