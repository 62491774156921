import axios from 'axios';

const emailTemplateUrl = '/v2/api/email/template';
const emailQueueUrl = '/v2/api/email/queue';

class EmailService {
    static createTemplate(templateData) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.post(emailTemplateUrl, templateData);
                const createdTemplate = result.data;
                resolve(createdTemplate);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getTemplateById(templateId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${emailTemplateUrl}/${templateId}`;
                const result = await axios.get(url);
                const templateData = result.data;
                resolve(templateData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateTemplate(templateId, updatedTemplateData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${emailTemplateUrl}/${templateId}`;
                await axios.put(url, updatedTemplateData);
                resolve(`Template with ID ${templateId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteTemplate(templateId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${emailTemplateUrl}/${templateId}`;
                await axios.delete(url);
                resolve(`Template with ID ${templateId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createQueueItem(queueData) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.post(emailQueueUrl, queueData);
                const createdQueueItem = result.data;
                resolve(createdQueueItem);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static searchAllEmailQueues(urlParams) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${emailQueueUrl}?${urlParams}`;
                const result = await axios.get(url);
                const pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }
    
    static getQueueItemById(queueId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${emailQueueUrl}/${queueId}`;
                const result = await axios.get(url);
                const queueItemData = result.data;
                resolve(queueItemData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateQueueItem(queueId, updatedQueueData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${emailQueueUrl}/${queueId}`;
                await axios.put(url, updatedQueueData);
                resolve(`Queue item with ID ${queueId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteQueueItem(queueId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${emailQueueUrl}/${queueId}`;
                await axios.delete(url);
                resolve(`Queue item with ID ${queueId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getEmailQueueWithTemplate(category) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = category ? `${emailQueueUrl}/with-template/${category}` : `${emailQueueUrl}/with-template`;
                const result = await axios.get(url);
                const emailQueueWithTemplateData = result.data;
                resolve(emailQueueWithTemplateData);
            } catch (error) {
                reject(error.response);
            }
        });
    }
}

export default EmailService;
