import axios from "axios";

const attendanceUrl = "/v2/api/attendances";

class AttendanceService {
    static getAttendances(urlP) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = [attendanceUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getAttendanceById(attendanceId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${attendanceUrl}/${attendanceId}`;
                const result = await axios.get(url);
                const attendanceData = result.data;
                resolve(attendanceData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createAttendance(attendanceData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${attendanceUrl}`;
                const result = await axios.post(url, attendanceData);
                const createdAttendance = result.data;
                resolve(createdAttendance);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateAttendance(attendanceId, updatedAttendanceData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${attendanceUrl}/${attendanceId}`;
                await axios.put(url, updatedAttendanceData);
                resolve(`Attendance record with ID ${attendanceId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteAttendance(attendanceId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${attendanceUrl}/${attendanceId}`;
                await axios.delete(url);
                resolve(`Attendance record with ID ${attendanceId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }
}

export default AttendanceService;
