import EquipmentScheduleService from "@/services/scheduleService";

export const ScheduleManageStore = {
    namespaced: true,
    state: {
        tableStates: {
            equipment: {
                tableData: [],
                totalRecords: 0,
                rowsPerPage: 10,
                currentPage: 1,
                sortField: null,
                sortOrder: -1,
                customer_id: null,
            },
        },
        currentTable: "equipment",
        isLoading: false,
    },
    mutations: {

        SET_TABLE_DATA(state, {tableName, data}) {
            const tableState = state.tableStates[tableName];

            if (tableState) {
                if (data && typeof data === 'object' && 'data' in data) {
                    tableState.tableData = data.data;
                    if ('count' in data) {
                        tableState.totalRecords = data.count;
                    }
                } else {
                    tableState.tableData = data;
                    tableState.totalRecords = Array.isArray(data) ? data.length : 0;
                }
            }
        },

        UPDATE_ROWS_PER_PAGE(state, {tableName, rows}) {
            const tableState = state.tableStates[tableName];
            if (tableState) {
                tableState.rowsPerPage = rows;
            }
        },

        UPDATE_CURRENT_PAGE(state, {tableName, page}) {
            const tableState = state.tableStates[tableName];
            if (tableState) {
                tableState.currentPage = page;
            }
        },

        UPDATE_SORT_OPTIONS(state, {tableName, field, order}) {
            const tableState = state.tableStates[tableName];
            if (tableState) {
                tableState.sortField = field;
                tableState.sortOrder = order;
            }
        },

        UPDATE_CURRENT_TABLE(state, currentTable) {
            state.currentTable = currentTable;
        },

        UPDATE_CURRENT_CUSTOMER_ID(state, {tableName, currentCustomerId}) {
            const tableState = state.tableStates[tableName];
            if (tableState) {
                tableState.customer_id = currentCustomerId;
            }
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
    },
    actions: {
        async fetchTableData({commit, state}) {
            commit("SET_LOADING", true);

            const tableState = state.tableStates[state.currentTable];
            const urlSegment = buildUrlSegment(state.currentTable, tableState);
            let params = buildParams(state.currentTable, tableState);
            const apiEndpoint = getApiEndpoint(state.currentTable);
            const queryString = buildQueryString(params);

            let fullUrl = "";

            if (urlSegment) {
                fullUrl += `/${urlSegment}`;
            }

            if (queryString) {
                fullUrl += `?${queryString}`;
            }

            try {

                const data = await apiEndpoint(fullUrl);

                commit("SET_TABLE_DATA", {tableName: state.currentTable, data});
            } catch (error) {
                commit("SET_TABLE_DATA", {tableName: state.currentTable, data: []});
                console.error("Error fetching data:", error);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        updateTableSettings({commit, dispatch, state}, {rows, page}) {
            commit("UPDATE_ROWS_PER_PAGE", {tableName: state.currentTable, rows});
            commit("UPDATE_CURRENT_PAGE", {tableName: state.currentTable, page: page + 1});
            dispatch("fetchTableData");
        },

        updateSortOptions({commit, dispatch, state}, {field, order}) {
            commit("UPDATE_SORT_OPTIONS", {tableName: state.currentTable, field, order});
            dispatch("fetchTableData");
        },

        updateCurrentTable({commit, dispatch}, currentTable) {
            commit("UPDATE_CURRENT_TABLE", currentTable);
            dispatch("fetchTableData");
        },

        updateCurrentCustomerId({commit, dispatch, state}, currentCustomerId) {
            commit("UPDATE_CURRENT_CUSTOMER_ID", {tableName: state.currentTable, currentCustomerId});
        },
    },
};

function buildQueryString(params) {
    if (params == null || Object.keys(params).length === 0 || Object.values(params).every(value => value === null)) {
        return "";
    }

    return Object.entries(params)
        .filter(([key, value]) => value != null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}


function buildParams(currentTable, tableState) {

    if (currentTable === 'equipment') {
        return;
    }
    let params = {
        page: tableState.currentPage,
        limit: tableState.rowsPerPage,
        orderBy: tableState.sortField,
        order: tableState.sortOrder,
    };
    if (tableState.customer_id) {
        params.ownerId = tableState.customer_id;
        params.expand = "owner";
    }
    return params;
}

function buildUrlSegment(currentTable, tableState) {
    let urlSegment = '';

    if (currentTable === 'equipment' && tableState.customer_id) {
        urlSegment = `owner-equipment/${tableState.customer_id}`;
    }

    return urlSegment;
}

function getApiEndpoint(tableName) {
    switch (tableName) {
        case 'equipment':
            return EquipmentScheduleService.getEquipmentsAndScheduleByCustomerId;
        default:
            return EquipmentScheduleService.getEquipmentsByCustomerId; // Replace with your default API endpoint
    }
}