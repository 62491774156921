import axios from "axios";
import { BANKHOLIDAYS } from "@/utils/constants";
class UtilService {
    static getBankHolidays() {
      return new Promise(async (resolve, reject) => {
        try {         
          resolve(BANKHOLIDAYS);
        } catch (error) {
          reject(error.response);
        }
      });
    }
}  

export default UtilService