import axios from 'axios'

const salesProspectUrl = '/api/prospects'

class SalesProspectService {
    // Get Prospects
    static getProspects(searchTerm, searchOptions) {
        return new Promise(async (resolve, reject) => {
            try {
                let options;
                if (searchTerm) {
                    options = `&searchNotes=${searchOptions.notes}&searchActions=${searchOptions.actions}`
                }
                let url = searchTerm ? `${salesProspectUrl}?searchTerm=${searchTerm}${options}` : salesProspectUrl
                let result = await axios.get(url)
                resolve(result.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }

    // Get Prospect by Id
    static getProspectById(prospectId) {
        return new Promise(async (resolve, reject) => {
            try {
                let prospectUrl = `${salesProspectUrl}/${prospectId}`
                let result = await axios.get(prospectUrl)
                resolve(result.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }

    // Create prospect
    static createProspect(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.post(salesProspectUrl, data)
                resolve(result.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }

    // Update prospect
    static updateProspect(prospectId, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let prospectUrl = `${salesProspectUrl}/${prospectId}`
                let result = await axios.put(prospectUrl, data)
                resolve(result.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }

    // Delete prospect
    static deleteProspect(prospectId, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let prospectUrl = `${salesProspectUrl}/${prospectId}`
                let result = await axios.delete(prospectUrl)
                resolve(result.data)
            } catch (err) {
                reject(rerr.response)
            }
        })
    }

    // Add notes to prospect
    static createProspectNote(prospectId, noteContent) {
        return new Promise(async (resolve, reject) => {
            try {
                let notesUrl = `${salesProspectUrl}/${prospectId}/notes`
                let result = await axios.post(notesUrl, {
                    note: noteContent
                })
                resolve(result.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }

    // Add notes to prospect
    static updateProspectNote(prospectId, noteId, noteContent) {
        return new Promise(async (resolve, reject) => {
            try {
                let notesUrl = `${salesProspectUrl}/${prospectId}/notes/${noteId}`
                let result = await axios.put(notesUrl, {
                    note: noteContent
                })
                resolve(result.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }

    // Add notes to prospect
    static deleteProspectNote(prospectId, noteId) {
        return new Promise(async (resolve, reject) => {
            try {
                let notesUrl = `${salesProspectUrl}/${prospectId}/notes/${noteId}`
                let result = await axios.delete(notesUrl)
                resolve(result.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }

    // Create prospect contact
    static createProspectContact(prospectId, contactData) {
        return new Promise(async (resolve, reject) => {
            try {
                let contactsUrl = `${salesProspectUrl}/${prospectId}/contacts`
                let result = await axios.post(contactsUrl, contactData)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Update prospect contact
    static updateProspectContact(prospectId, contactId, contactData) {
        return new Promise(async (resolve, reject) => {
            try {
                let contactsUrl = `${salesProspectUrl}/${prospectId}/contacts/${contactId}`
                let result = await axios.put(contactsUrl, contactData)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Remove prospect contact
    static deleteProspectContact(prospectId, contactId) {
        return new Promise(async (resolve, reject) => {
            try {
                let deleteUrl = `${salesProspectUrl}/${prospectId}/contacts/${contactId}`
                let result = await axios.delete(deleteUrl)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Create prospect action
    static createProspectAction(prospectId, actionData) {
        return new Promise(async (resolve, reject) => {
            try {
                let actionsUrl = `${salesProspectUrl}/${prospectId}/events`
                let result = axios.post(actionsUrl, actionData)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Update prospect action
    static updateProspectAction(prospectId, actionId, actionData) {
        return new Promise(async (resolve, reject) => {
            try {
                let actionsUrl = `${salesProspectUrl}/${prospectId}/events/${actionId}`
                let result = axios.put(actionsUrl, actionData)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Update prospect action
    static deleteProspectAction(prospectId, actionId) {
        return new Promise(async (resolve, reject) => {
            try {
                let actionsUrl = `${salesProspectUrl}/${prospectId}/events/${actionId}`
                let result = axios.delete(actionsUrl)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Get attachments
    static getProspectAttachments(prospectId) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentsUrl = `${salesProspectUrl}/${prospectId}/attachments`
                let result = await axios.get(attachmentsUrl)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Get attachment by ID
    static getProspectAttachmentById(attachmentId) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentUrl = `/api/files/${attachmentId}`
                let result = await axios.get(attachmentUrl)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Create attachment
    static createProspectAttachment(prospectId, attachmentData) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentsUrl = `${salesProspectUrl}/${prospectId}/attachments`
                let result = await axios.post(attachmentsUrl, attachmentData)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Update attachment
    static updateProspectAttachment(prospectId, attachmentId, attachmentData) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentsUrl = `${salesProspectUrl}/${prospectId}/attachments/${attachmentId}`
                let result = await axios.put(attachmentsUrl, attachmentData)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Delete attachment
    static deleteProspectAttachmentById(attachmentId) {
        return new Promise(async (resolve, reject) => {
            try {
                let attachmentUrl = `/api/files/${attachmentId}`
                let result = await axios.delete(attachmentUrl)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Find Address
    static findAddressFromPostcode(postcode) {
        return new Promise(async (resolve, reject) => {
            try {
                let addressUrl = `/api/tools/addresslookup/${postcode}`
                let result = await axios.get(addressUrl)
                resolve(result)
            }
            catch (err) {
                console.error(err)
                reject(err.response)
            }
        })
    }

    // Print Company Report
    static printCompanyReport(prospectId) {
        return new Promise(async (resolve, reject) => {
            try {
                let prospectUrl = `${salesProspectUrl}/${prospectId}/export/companyreport`
                let result = await axios.get(prospectUrl)
                resolve(result.data)
            }
            catch (err) {
                reject(err.response)
            }
        })
    }
}

export default SalesProspectService