<template>
  <div>
    <div id="app" class="flex fixed inset-0">
      <nav
          class="h-full bg-secondary flex flex-col justify-between"
          v-if="isLoggedIn()"
      >
        <div>
          <div class="h-16 w-full flex justify-center items-center">
            <img
                class="h-8 w-8"
                src="@/assets/boston-b-white.png"
                alt="Boston Logo"
            />
          </div>
          <div class="mx-2">
            <!-- Dashboard -->
            <div class="my-2 relative group">
              <button
                  @click="navTo('dashboard')"
                  :class="{
                  'border-primary shadow-inner':
                    $router.currentRoute.path == '/',
                  'border-secondary hover:shadow':
                    $router.currentRoute.path != '/',
                }"
                  class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
              >
                <svg
                    class="h-8 w-8 stroke-current"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M3 3h7v7H3zM14 3h7v7h-7zM14 14h7v7h-7zM3 14h7v7H3z"
                  />
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Dashboard
                </div>
              </div>
            </div>
            <!-- /Dashboard -->

            <!-- Contacts -->
            <div class="my-2 relative group">
              <button
                  @click="navTo('contacts')"
                  :class="navIsActiveClass('contacts')"
                  class="mx-auto p-1 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
              >
                <svg
                    class="h-10 w-10 stroke-current"
                    fill="none"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <rect width="18" height="16" x="3" y="4" rx="3"/>
                  <circle cx="9" cy="10" r="2"/>
                  <path d="M15 8h2M15 12h2M7 16h10"/>
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Contacts
                </div>
              </div>
            </div>
            <!-- /Contacts -->

            <!-- Sales Enquiries -->
            <div class="my-2 relative group">
              <button
                  @click="navTo('prospect-index')"
                  :class="navIsActiveClass('prospects')"
                  class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
              >
                <svg
                    class="h-8 w-8 stroke-current"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"
                  />
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Sales Enquiries
                </div>
              </div>
            </div>
            <!-- /Sales Enquiries -->

            <!-- Products -->
            <div class="my-2 relative group">
              <button
                  @click="navTo('product-index')"
                  :class="navIsActiveClass('products')"
                  class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
              >
                <svg
                    class="h-8 w-8 stroke-current"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <!-- <ellipse cx="12" cy="5" rx="9" ry="3" />
              <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3" />
                <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5" />-->
                  <path
                      d="M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"
                  />
                  <path d="M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12"/>
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Products
                </div>
              </div>
            </div>
            <!-- /Products -->

            <!-- Service Tickets -->
            <div class="my-2 relative group">
              <button
                  class="mx-auto p-1 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
                  :class="navIsActiveClass('tickets')"
                  @click="navTo('service-tickets')"
              >
                <svg
                    class="h-10 w-10 stroke-current"
                    fill="none"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
                  />
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Service Tickets
                </div>
              </div>
            </div>
            <!-- /Service Tickets -->

            <!-- Equipment -->
            <div class="my-2 relative group">
              <button
                  class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
                  :class="navIsActiveClass('equipment')"
                  @click="navTo('equipment-index')"
              >
                <svg
                    class="h-8 w-8 stroke-current"
                    fill="none"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <path d="M1 3h15v13H1zM16 8h4l3 3v5h-7V8z"/>
                  <circle cx="5.5" cy="18.5" r="2.5"/>
                  <circle cx="18.5" cy="18.5" r="2.5"/>
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Equipment
                </div>
              </div>
            </div>
            <!-- /Equipment -->

            <!-- Published Docs -->
            <div class="my-2 relative group">
              <button
                  class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
                  :class="navIsActiveClass('documents')"
                  @click="navTo('documents')"
              >
                <svg
                    class="h-8 w-8 stroke-current"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M13 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V9z"
                  />
                  <path d="M13 2v7h7"/>
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Published Docs
                </div>
              </div>
            </div>
            <!-- /Published Docs -->

            <!-- Tools -->
            <div class="my-2 relative group">
              <button
                  @click="navTo('tools')"
                  :class="navIsActiveClass('tools')"
                  class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
              >
                <svg
                    class="h-8 w-8 stroke-current"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M14.7 6.3a1 1 0 000 1.4l1.6 1.6a1 1 0 001.4 0l3.77-3.77a6 6 0 01-7.94 7.94l-6.91 6.91a2.12 2.12 0 01-3-3l6.91-6.91a6 6 0 017.94-7.94l-3.76 3.76z"
                  />
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Tools
                </div>
              </div>
            </div>
            <!-- /Tools -->
            <!-- HR -->
            <div class="my-2 relative group">
              <button
                  @click="navTo('internal')"
                  :class="navIsActiveClass('internal')"
                  class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
              >
                <svg
                    class="h-8 w-8 stroke-current"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  Internal
                </div>
              </div>
            </div>
            <!-- /HR -->

            <!-- Service Tickets -->
            <div class="my-2 relative group">
              <button
                  class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
                  :class="navIsActiveClass('faqs')"
                  @click="navTo('faqs')"
              >
                <svg
                    class="h-8 w-8 stroke-current"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <path
                      d="M8 9h8M8 13h6M14 18h-1l-5 3v-3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4.5M19 22v.01M19 19a2.003 2.003 0 0 0 .914-3.782 1.98 1.98 0 0 0-2.414.483"/>
                </svg>
              </button>
              <div
                  class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
              >
                <div
                    class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
                >
                  FAQs
                </div>
              </div>
            </div>


            <div class="my-2 relative group">

            </div>

          </div>
        </div>

        <div class="my-2 relative group mt-auto">
          <div class="my-2 relative group">
            <button
                @click="navTo('settings')"
                :class="navIsActiveClass('settings')"
                class="mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 hover:border-primary bg-secondary hover:bg-primary rounded transition-colors ease-in-out duration-150"
            >
              <svg
                  class="h-8 w-8 stroke-current"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
              >
                <circle cx="12" cy="12" r="3"/>
                <path
                    d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09a1.65 1.65 0 0 0-1-1.51 1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09a1.65 1.65 0 0 0 1.51-1 1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33h.01a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82v.01a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
              </svg>
            </button>
            <div
                class="top-0 absolute group-hover:flex h-full hidden items-center left-0 ml-18 z-50"
            >
              <div
                  class="bg-orange-400 px-4 py-2 rounded-md leading-none whitespace-no-wrap"
              >
                Settings
              </div>
            </div>
          </div>
        </div>


        <div class="mx-2">
          <div class="relative">
            <div
                v-show="profileMenuOpen"
                @click="profileMenuOpen = false"
                class="fixed inset-0 z-50"
            ></div>
            <button
                @click="openProfileMenu"
                class="relative my-2 mx-auto p-2 flex flex-col items-center justify-center text-primary hover:text-secondary border-2 border-secondary hover:border-primary bg-secondary hover:bg-primary hover:shadow rounded transition-colors ease-in-out duration-150"
            >
              <img class="w-10 rounded-full" :src="userPicture" alt/>
            </button>
            <transition name="scale-bl">
              <div
                  v-show="profileMenuOpen"
                  class="ml-8 mb-6 bottom-0 absolute bg-white w-56 shadow-2xl rounded-lg overflow-hidden z-50"
              >
                <div class="flex flex-col items-center pt-6 pb-4 px-8">
                  <img class="w-20 rounded-full" :src="userPicture" alt/>
                  <h2 class="text-xl mt-4 leading-none">{{ userName }}</h2>
                  <span class="block text-sm text-gray-600 leading-none mt-1">{{
                      userEmail
                    }}</span>
                  <div class="flex mt-4 -mx-1">
                    <button
                        @click="labsOpen = true"
                        class="mx-1 border border-transparent hover:border-gray-500 hover:shadow active:border-gray-500 active:shadow-inner transition duration-300 rounded p-2 flex items-center"
                    >
                      <svg
                          class="fill-current h-6 w-6 mr-2"
                          viewBox="0 0 24 24"
                      >
                        <path
                            d="M6.423 4.509c-.893-.34-1.534-1.178-1.534-2.083V2.31C4.889 1.022 6.012 0 7.393 0h6.214c1.382 0 2.505 1.022 2.505 2.309v.079c0 .944-.643 1.782-1.534 2.12v5.951l5.643 6.69c1.04 1.23.803 2.141.61 2.575-.37.789-1.316 1.265-2.66 1.265H2.83c-1.345 0-2.291-.476-2.665-1.273-.19-.426-.427-1.336.613-2.566l5.644-6.69V4.508zm6.45 5.146H8.128v1.272c0 .1-.035.196-.1.272L2.052 18.24c-.158.189-.273.368-.327.533-.03.087-.047.165-.014.231.042.084.151.137.287.189.216.084.5.13.832.13h15.34c.333 0 .617-.046.832-.13.137-.052.246-.105.288-.189.032-.066.015-.144-.014-.23a1.71 1.71 0 00-.325-.53l-5.978-7.045a.423.423 0 01-.1-.272V9.655zm0-1.667V3.45a.42.42 0 01.379-.417l.41-.042c.418-.024.746-.258.746-.603v-.08c0-.374-.377-.642-.8-.642H7.392c-.424 0-.8.268-.8.643v.117c0 .319.33.541.746.565l.411.042a.42.42 0 01.378.417v4.538h4.745z"
                        />
                      </svg>
                      <span class="uppercase text-sm">Labs</span>
                    </button>
                    <button
                        @click="logout()"
                        class="mx-1 border border-transparent hover:border-gray-500 hover:shadow active:border-gray-500 active:shadow-inner transition duration-300 rounded p-2 flex items-center"
                    >
                      <svg
                          class="stroke-current h-6 w-6 mr-2"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                      >
                        <path
                            d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4M16 17l5-5-5-5M21 12H9"
                        />
                      </svg>
                      <span class="uppercase text-sm">Logout</span>
                    </button>
                  </div>
                </div>
                <div
                    class="w-full bg-gray-200 text-gray-600 text-sm text-center py-1 relative"
                >
                  <div
                      v-if="loadingVersion"
                      class="absolute inset-0 bg-gray-200 flex items-center justify-center"
                  >
                    <spinner :color="'text-gray-600'" :size="6"/>
                  </div>
                  <h5 class="font-bold">Version</h5>
                  <span>UI: 1.8.4</span>
                  <span>{{ " / " }}</span>
                  <span>API: {{ apiVersion }}</span>
                </div>
              </div>
            </transition>
            <div
                v-show="labsOpen"
                @click="labsOpen = false"
                class="fixed inset-0 z-50"
            ></div>
            <transition name="scale-bl">
              <Labs
                  v-if="labsOpen"
                  @close="labsOpen = false"
                  @updated-experiment="$forceUpdate()"
              />
            </transition>
          </div>
        </div>
      </nav>

      <router-view/>

      <portal to="overlay-outlet">
        <alert :showing="$store.state.navPrevented" title="Leave page">
          <p>Are you sure you want to leave this page with unsaved changes?</p>
          <div class="flex w-full mt-10 -mx-2">
            <div class="w-1/2 px-2">
              <button
                  @click="stayOnPage"
                  class="w-full h-full flex items-center justify-center bg-green-200 hover:bg-green-300 text-secondary py-2 px-4 font-semibold rounded border border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
              >
                No, stay on page
              </button>
            </div>
            <div class="w-1/2 px-2">
              <button
                  @click="leavePage"
                  class="w-full h-full flex items-center justify-center bg-red-200 hover:bg-red-300 text-secondary py-2 px-4 font-semibold rounded border border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-100"
              >
                Yes, leave without saving
              </button>
            </div>
          </div>
        </alert>
      </portal>
    </div>
    <portal-target name="overlay-outlet" multiple/>
    <ConfirmDialog/>
    <Toast/>
  </div>
</template>

<script>
const Alert = () => import("@/components/Alert.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Labs = () => import("@/components/Tools/Labs.vue");
import FaqIcon from "./assets/icons/faq-icon.vue";

export default {
  components: {
    Alert,
    Spinner,
    Labs,
    FaqIcon,
  },
  data() {
    return {
      user: {},
      selectedRoute: "dashboard",
      labsOpen: false,
      panelOpen: false,
      profileMenuOpen: false,
      apiVersion: "",
      loadingVersion: false,
    };
  },
  watch: {
    $route(to, from) {
      this.selectedRoute = to.name;
    },
  },
  mounted() {
    if (this.isLoggedIn()) {
      this.user = this.userInfo();
    }
  },
  computed: {
    userName() {
      if (this.isLoggedIn()) {
        let user = this.userInfo();
        return user.name;
      }
    },
    userEmail() {
      if (this.isLoggedIn()) {
        let user = this.userInfo();
        return user.email;
      }
    },
    userPicture() {
      if (this.isLoggedIn()) {
        let user = this.userInfo();
        return user.picture;
      }
    },
    userIsDeveloper() {
      if (this.isLoggedIn()) {
        return this.userEmail == "chris@bostonequipment.com" ? true : false;
      }
    },
  },
  methods: {
    navTo: function (routeName) {
      if (routeName == "prospect-index") {
        this.$store.dispatch("setEnquiriesSearchTerm", "");
      }
      if (routeName == "product-index") {
        this.$store.dispatch("setProductSearchTerm", "");
      }
      this.$router.push({name: routeName});
    },
    navIsActiveClass(navRouteName) {
      return this.$router.currentRoute.path.split("/")[1] == navRouteName
          ? "border-primary shadow-inner"
          : "border-secondary hover:shadow";
    },
    closePanel() {
      this.panelOpen = false;
    },
    isLoggedIn() {
      return this.AuthService.isLoggedIn();
    },
    userInfo() {
      return this.AuthService.getUserInfo();
    },
    logout() {
      this.AuthService.logout();
    },
    stayOnPage() {
      this.$store.dispatch("clearNavPrevented");
      this.$store.dispatch("setNavPreventedTo", null);
    },
    leavePage() {
      this.$store.dispatch("setFormClean");
      this.$store.dispatch("clearNavPrevented");
      this.$router.push({name: this.$store.state.navPreventedTo});
      this.$store.dispatch("setNavPreventedTo", null);
    },
    openProfileMenu: async function () {
      this.profileMenuOpen = true;
      this.loadingVersion = true;
      try {
        var result = await this.$axios.get("/api/version");
        var result2 = await this.$axios.get("/v2/api/version");
        this.apiVersion = `${result.data} / ${result2.data}`;
      } catch (err) {
        console.error(err);
      }
      this.loadingVersion = false;
    },
  },
};
</script>
