import axios from "axios";

const incidentUrl = "/v2/api/healthnsafety/incident";

class IncidentService {
  // Get all Incidents
  static getIncidents(pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};

        let url = [
          incidentUrl,
          `?page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
        ].join("");

        let result = await axios.get(url);

        if (result.data) {
          if (result.data.incident_date) {
            result.data.incident_date = new Date(result.data.incident_date);
          }
        }

        let pagingData = result.data;

        resolve(pagingData);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Incident by Id
  static getIncidentById(incidentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${incidentUrl}/${incidentId}?expand=agent`;
        let result = await axios.get(url);

        resolve(result.data);

        if (result.data) {
          if (result.data.incident_date) {
            result.data.incident_date = new Date(result.data.incident_date);
          }
        }
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Search Incident
  static searchIncident(searchTerm, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        let url = [
          incidentUrl,
          `?searchTerm=${encodeURIComponent(searchTerm)}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
        ].join("");

        let result = await axios.get(url);

        if (result.data) {
          if (result.data.incident_date) {
            result.data.incident_date = new Date(result.data.incident_date);
          }
        }

        let pagingData = result.data;
        resolve(pagingData);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  // Create Incident
  static createIncident(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(incidentUrl, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Incident
  static updateIncident(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${incidentUrl}/${data.incident_id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  //Remove Incident

  static deleteIncident(incidentId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${incidentUrl}/${incidentId}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Create Connected People
  static createConnectedPeople(incidentId, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${incidentUrl}/${incidentId}/incident`;
        let result = await axios.post(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Update Connected People
  static updateConnectedPeople(incidentId, id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${incidentUrl}/${incidentId}/incident/${id}`;
        let result = await axios.put(url, data);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }

  // Remove Connected People
  static deleteConnectedPeople(incidentId, id) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${incidentUrl}/${incidentId}/incident/${id}`;
        let result = await axios.delete(url);
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err.response);
      }
    });
  }
}
export default IncidentService;
