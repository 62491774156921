import axios from 'axios'

const transactionUrl = '/api/stripetransactions'

class StripeTransactionService {
    // Get Transactions
    static getTransactions() {
        return new Promise(async (resolve, reject) => {
            try {
                let result = await axios.get(transactionUrl)
                resolve(result.data)
            }
            catch (error) {
                reject(error.response)
            }
        })
    }

    // Get Transaction Data
    static getTransactionData(stripeEventId) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${transactionUrl}/${stripeEventId}`
                let result = await axios.get(url)
                resolve(result.data)
            } catch (error) {
                reject(error.response)
            }
        })
    }

    // Get Transaction History
    static getTransactionHistory(stripeEventId) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = `${transactionUrl}/${stripeEventId}/history`
                let result = await axios.get(url)
                resolve(result.data)
            } catch (error) {
                reject(error.response)
            }
        })
    }
}

export default StripeTransactionService