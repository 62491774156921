import axios from "axios";

const scheduleUrl = "/v2/api/schedule";
const equipmentUrl = "/v2/api/equipment";
const serviceUrl = "/v2/api/equipment/service/upcoming-services";


class EquipmentScheduleService {
    static searchEquipmentUpComingService(urlP) {

        return new Promise(async (resolve, reject) => {
            try {
                let url = [serviceUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }


    static searchAllSchedules(urlP) {

        return new Promise(async (resolve, reject) => {
            try {
                let url = [scheduleUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getEquipmentsByCustomerId(urlP) {

        return new Promise(async (resolve, reject) => {
            try {
                let url = [equipmentUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getEquipmentsAndScheduleByCustomerId(urlP) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = [scheduleUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getScheduleById(schedule_id) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${scheduleUrl}/${schedule_id}`;
                const result = await axios.get(url);
                const scheduleData = result.data;
                resolve(scheduleData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getScheduleByJobId(job_id) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${scheduleUrl}/job/${job_id}`;
                const result = await axios.get(url);
                const scheduleData = result.data;
                resolve(scheduleData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createSchedule(scheduleData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = scheduleUrl;
                const result = await axios.post(url, scheduleData);
                const createdSchedule = result.data;
                resolve(createdSchedule);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateSchedule(scheduleId, updatedScheduleData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${scheduleUrl}/${scheduleId}`;
                await axios.put(url, updatedScheduleData);
                resolve(`Schedule record with ID ${scheduleId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }
}

export default EquipmentScheduleService;
