import axios from "axios";
import store from "@/store";

const ticketsUrl = "/v2/api/tickets";

class ServiceTicketService {
  // Log Service Ticket
  static logServiceTicket(serviceTicket) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(ticketsUrl, serviceTicket);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static getServiceTicketById(ticket_id) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${ticketsUrl}/${ticket_id}?expand=equipment,comments`
        );
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static updateServiceTicket(ticket_id, ticket) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${ticketsUrl}/${ticket_id}`, ticket);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static closeTicket(ticket_id) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${ticketsUrl}/${ticket_id}/close`);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static setIsNewStatus(ticket_id, status) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.put(`${ticketsUrl}/${ticket_id}`, {
          is_new: status,
        });
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Service Tickets
  static getServiceTickets(pagingOpts, showClosed, contactId, searchTerm) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        showClosed = showClosed || false;
        contactId = contactId || "";
        console.log(
          `[serviceTicketService] getServiceTickets: ContactId passed in is: ${contactId}`
        );
        let url = [
          ticketsUrl,
          `?page=${pagingOpts.page || 1}`,
          searchTerm ? `&searchTerm=${searchTerm}` : "",
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
          `&includeClosed=${showClosed}`,
          `&contactId=${contactId}`,
        ].join("");
        let result = await axios.get(url);
        console.log(result.data);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}

export default ServiceTicketService;
