
import Vue from 'vue';
const defultValues = (sortField) => ({
    tableData: [],
    totalRecords: 0,
    rowsPerPage: 10,
    currentPage: 1,
    sortField: sortField,
    sortOrder: 1,
    searchText: "",
    selectedCategory: null,
    isloading: false
});
// Factory function to create a generic store module
const createGenericStore = (sortField, currentTab, fetchDataFunction) => ({
    namespaced: true,
    state: {
        tabs: {},
        currentTab: currentTab || "all",
    },
    mutations: {
        SET_TABLE_DATA(state, { tab, data }) {
            if (!state.tabs[tab]) {
                // If the tab doesn't exist, initialize it with default properties
                Vue.set(state.tabs, tab, defultValues(sortField));
            }
            Vue.set(state.tabs[tab], 'tableData', data.data);
            Vue.set(state.tabs[tab], 'totalRecords', data.count);
        },
        UPDATE_LOADING(state, { tab, loading }) {
            if (!state.tabs[tab]) {
                // If the tab doesn't exist, initialize it with default properties
                Vue.set(state.tabs, tab, defultValues(sortField));
            }
            Vue.set(state.tabs[tab], "isloading", loading);
        },
        UPDATE_CURRENT_PAGE(state, page) {
            if (state.currentTab && state.tabs[state.currentTab]) {
                state.tabs[state.currentTab].currentPage = page;
            }
        },
        UPDATE_ROWS_PER_PAGE(state, rows) {
            if (state.currentTab && state.tabs[state.currentTab]) {
                state.tabs[state.currentTab].rowsPerPage = rows;
            }
        },
        UPDATE_SORT_OPTIONS(state, { field, order }) {
            if (state.currentTab && state.tabs[state.currentTab]) {
                state.tabs[state.currentTab].sortField = field;
                state.tabs[state.currentTab].sortOrder = order;
            }
        },
        UPDATE_SEARCH_TEXT(state, searchText) {
            if (state.currentTab && state.tabs[state.currentTab]) {
                state.tabs[state.currentTab].searchText = searchText;
            }
        },
        UPDATE_SELECTED_CATEGORY(state, selectedCategory) {
            if (state.currentTab && state.tabs[state.currentTab]) {
                state.tabs[state.currentTab].selectedCategory = selectedCategory;
            }
        },
        UPDATE_CURRENT_TAB(state, currentTab) {
            state.currentTab = currentTab;
        },
    },
    actions: {
        fetchTableData({ commit, state }) {
            let currentTabState = state.tabs[state.currentTab];
            if (currentTabState === undefined) {
                currentTabState = defultValues(sortField);
            }

            let params = {
                page: currentTabState.currentPage,
                limit: currentTabState.rowsPerPage,
                orderBy: currentTabState.sortField,
                order: currentTabState.sortOrder,
            };

            if (currentTabState.searchText) {
                params = {
                    ...params,
                    searchTerm: currentTabState.searchText,
                    // category: currentTabState.selectedCategory,
                };
            }

            const queryString = Object.entries(params)
                .map(
                    ([key, value]) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
                )
                .join("&");

            commit("UPDATE_LOADING", { tab: state.currentTab, loading: true });
            fetchDataFunction(`${state.currentTab}?${queryString}`)
                .then((data) => {
                    commit("SET_TABLE_DATA", { tab: state.currentTab, data });
                    commit("UPDATE_LOADING", { tab: state.currentTab, loading: false });
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    commit("UPDATE_LOADING", { tab: state.currentTab, loading: false });
                });
        },
        updateTableSettings({ commit, dispatch }, { rows, page }) {
            commit("UPDATE_ROWS_PER_PAGE", rows);
            commit("UPDATE_CURRENT_PAGE", page + 1);
            dispatch("fetchTableData");
        },
        updateSortOptions({ commit, dispatch }, { field, order }) {
            commit("UPDATE_SORT_OPTIONS", { field, order });
            dispatch("fetchTableData");
        },
        updateSearchCriteria(
            { commit, dispatch },
            { searchText, selectedCategory },
        ) {
            commit("UPDATE_SEARCH_TEXT", searchText);
            commit("UPDATE_SELECTED_CATEGORY", selectedCategory);
            dispatch("fetchTableData");
        },
        updateTab({ commit, dispatch }, currentTab) {
            commit("UPDATE_CURRENT_TAB", currentTab);
            dispatch("fetchTableData");
        },
    },
});

export default createGenericStore;
