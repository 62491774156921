import axios from "axios";

const jobSheetUrl = "/v2/api/job-sheets";

class JobSheetService {
    static getJobSheets(urlP) {
        return new Promise(async (resolve, reject) => {
            try {

                let url = [jobSheetUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getJobSheetById(jobSheetId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${jobSheetUrl}/${jobSheetId}`;
                const result = await axios.get(url);
                const jobSheetData = result.data;
                resolve(jobSheetData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getJobSheetsByCustomerId(customerId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${jobSheetUrl}/by-customer/${customerId}`;
                const result = await axios.get(url);
                const jobSheetsData = result.data;
                resolve(jobSheetsData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createJobSheet(jobSheetData) {

        return new Promise(async (resolve, reject) => {
            try {
                const url = `${jobSheetUrl}`;
                const result = await axios.post(url, jobSheetData);
                const createdJobSheet = result.data;
                resolve(createdJobSheet);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateJobSheet(jobSheetId, updatedJobSheetData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${jobSheetUrl}/${jobSheetId}`;
                await axios.put(url, updatedJobSheetData);
                resolve(`Job Sheet record with ID ${jobSheetId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteJobSheet(jobSheetId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${jobSheetUrl}/${jobSheetId}`;
                await axios.delete(url);
                resolve(`Job Sheet record with ID ${jobSheetId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static uploadDocuments(files, jobSheetId) {
        return new Promise(async (resolve, reject) => {
            try {
                // Prepare an array of file data objects
                const filesToUpload = files.map((file) => ({
                    filename: file.name,
                    content_type: file.content_type,
                    visibility: "Internal",
                    category: "job-sheets",
                    name: file.name,
                    unique_id: file.unique_id,
                    requires_thumbnail: (file.requires_thumbnail !== undefined) ? file.requires_thumbnail : false,
                    document_role: (file.document_role !== undefined) ? file.document_role : 'attachment',

                }));

                console.log("Files are:", files);
                console.log("Documents to upload are:", filesToUpload);

                const jobSheetDocumentUrl = `${jobSheetUrl}/${jobSheetId}/documents`;

                // Post the array of file data objects to the server
                let result = await axios.post(jobSheetDocumentUrl, filesToUpload);
                console.log(result);
                resolve(result.data);
            } catch (error) {
                console.error("Error uploading documents:", error);
                reject(error.response);
            }
        });
    }

    static async getJobSheetDocuments(jobSheetId, documentRole) {
        try {
            const jobSheetDocumentUrl = `${jobSheetUrl}/${jobSheetId}/documents`;

            const jobSheetDocumentUrlWithRole = documentRole ? `${jobSheetDocumentUrl}?document_role=${encodeURIComponent(documentRole)}` : jobSheetDocumentUrl;
            // Fetch the documents
            let result = await axios.get(jobSheetDocumentUrlWithRole);
            return result.data;
        } catch (error) {
            console.error("Error fetching job sheet documents:", error);
            throw error;
        }
    }

    static async deleteJobSheetDocuments(jobSheetId, documentIds) {
        try {
            const jobSheetDocumentUrl = `${jobSheetUrl}/${jobSheetId}/documents`;

            // Prepare the request body
            await axios.delete(jobSheetDocumentUrl, {
                data: documentIds,
                headers: {"Content-Type": "application/json"},
            });

            console.log(`Documents deleted successfully for jobSheetId: ${jobSheetId}`);
        } catch (error) {
            console.error("Error deleting job sheet documents:", error);
            throw error;
        }
    }
}

export default JobSheetService;
