import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import moment from "@/utils/businessDayFinder";
import validator from "validator";
import _ from "lodash";
import AuthService from "@/services/authService";
import SalesProspectService from "@/services/salesProspectService";
import ProductService from "@/services/productService";
import UserService from "@/services/userService";
import ReturnCodeService from "@/services/returnCodeService";
import iVClientService from "@/services/iVClientService";
import ServiceTicketService from "@/services/serviceTicketService";
import StripeTransactionService from "@/services/stripeTransactionService";
import ContactService from "@/services/contactService";
import CommentService from "@/services/commentService";
import DocumentService from "@/services/documentService";
import EngineerService from "@/services/engineerService";
import EquipmentService from "@/services/equipmentService";
import LoanEquipmentService from '@/services/loanEquipmentService';
import FleetService from "@/services/fleetService";
import StaffService from "@/services/staffService";
import HolidayService from "@/services/holidayService";
import InternalDocumentService from "@/services/internalDocumentService";
import IncidentService from "@/services/IncidentService";
import GoodsInService from "@/services/goodsInService";
import JobSheetService from "@/services/jobSheetService";
import UtilService from "@/services/utilService";
import RepairService from "@/services/repairService";
import QuotationService from "@/services/quotationService";
import CertificateService from "@/services/certificateService";
import DatamasterService from "@/services/dataMasterService";
import PurchaseOrderService from "@/services/purchaseService";
import EmailService from "@/services/emailService";
import AttendanceService from "@/services/attendanceService";
import SalesOrderService from "@/services/salesService";


import PrimeVue from "primevue/config";
import Calendar from "primevue/calendar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import "primevue/resources/themes/saga-orange/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Button from "primevue/button";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import AutoComplete from "primevue/autocomplete";
import FAQService from "./services/faqService";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Card from "primevue/card";
import Tooltip from "primevue/tooltip";
import Galleria from "primevue/galleria";
import FileUpload from "primevue/fileupload";
import Checkbox from "primevue/checkbox";
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Timeline from 'primevue/timeline';
import Textarea from 'primevue/textarea';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import InputNumber from 'primevue/inputnumber';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import RadioButton from 'primevue/radiobutton';
import Knob from 'primevue/knob';
import Chips from 'primevue/chips';
import Paginator from 'primevue/paginator';
import TreeTable from 'primevue/treetable';
import Tree from 'primevue/tree';

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$validator = validator;
Vue.prototype.$_ = _;
Vue.prototype.AuthService = AuthService;
Vue.prototype.SalesProspectService = SalesProspectService;
Vue.prototype.ProductService = ProductService;
Vue.prototype.UserService = UserService;
Vue.prototype.ReturnCodeService = ReturnCodeService;
Vue.prototype.iVClientService = iVClientService;
Vue.prototype.ServiceTicketService = ServiceTicketService;
Vue.prototype.StripeTransactionService = StripeTransactionService;
Vue.prototype.ContactService = ContactService;
Vue.prototype.CommentService = CommentService;
Vue.prototype.DocumentService = DocumentService;
Vue.prototype.EngineerService = EngineerService;
Vue.prototype.EquipmentService = EquipmentService;
Vue.prototype.FleetService = FleetService;
Vue.prototype.StaffService = StaffService;
Vue.prototype.HolidayService = HolidayService;
Vue.prototype.InternalDocumentService = InternalDocumentService;
Vue.prototype.IncidentService = IncidentService;
Vue.prototype.GoodsInService = GoodsInService;
Vue.prototype.UtilService = UtilService;
Vue.prototype.faqService = FAQService;
Vue.prototype.LoanEquipmentService = LoanEquipmentService;
Vue.prototype.EquipmentScheduleService = EquipmentScheduleService;
Vue.prototype.JobSheetService = JobSheetService;
Vue.prototype.RepairService = RepairService;
Vue.prototype.QuotationService = QuotationService;
Vue.prototype.CertificateService = CertificateService;
Vue.prototype.DatamasterService = DatamasterService;
Vue.prototype.PurchaseOrderService = PurchaseOrderService;
Vue.prototype.EmailService = EmailService;
Vue.prototype.AttendanceService = AttendanceService;
Vue.prototype.SalesOrderService = SalesOrderService;

Vue.prototype.$getExperimentStatus = function (experiment) {
    return (
        JSON.parse(window.sessionStorage.getItem(`experiment-` + experiment)) ||
        false
    );
};

import PortalVue from "portal-vue";
import VueGoodTablePlugin from "vue-good-table";
import {BreadstickBakery} from "breadstick";

import "./assets/css/tailwind.css";
import "./assets/css/animations.css";
import "./assets/css/style.css";
import "./assets/css/primevue.css";
import "vue-good-table/dist/vue-good-table.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import EquipmentScheduleService from "@/services/scheduleService";

Vue.use(VueQuillEditor)

function contentWatchOverride(newVal, oldVal) {
    if (this.quill) {
        if (newVal && newVal !== this._content) {

            this._content = newVal
            const delta = this.quill.clipboard.convert(newVal)
            this.quill.setContents(delta)
        } else if (!newVal) {
            this.quill.setText('')
        }
    }
}

const Base = Vue.options.components['quill-editor']
const CustomQuillEditor = Base.extend({
    beforeCreate() {
        Vue.set(this.$options.watch, 'content', contentWatchOverride.bind(this))
        Vue.set(this.$options.watch, 'value', contentWatchOverride.bind(this))
    }
})

Vue.use(PortalVue);
Vue.use(VueGoodTablePlugin);
Vue.use(BreadstickBakery);
Vue.use(PrimeVue, {ripple: true});
Vue.use(ToastService);
Vue.use(ConfirmationService);


Vue.component('Editor', CustomQuillEditor)
Vue.component("Calendar", Calendar);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("Button", Button);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.component("Toast", Toast);
Vue.component("AutoComplete", AutoComplete);
Vue.component("TabView", TabView);
Vue.component("TabPanel", TabPanel);
Vue.component("Galleria", Galleria);
Vue.component("FileUpload", FileUpload);
Vue.component("Checkbox", Checkbox);
Vue.component("Dialog", Dialog);
Vue.component("Dropdown", Dropdown);
Vue.component("Timeline", Timeline);
Vue.component("Textarea", Textarea);
Vue.component("InputNumber", InputNumber);
Vue.component("Accordion", Accordion);
Vue.component("AccordionTab", AccordionTab);
Vue.component("RadioButton", RadioButton);
Vue.component("Knob", Knob);
Vue.component("Chips", Chips);
// If we have an auth token in storage, add it to default request headers
/*let token = getAccessToken()
if (token && isLoggedIn()) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}*/
Vue.component("Card", Card);
Vue.component("AutoComplete", AutoComplete);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("Button", Button);
Vue.component("InputSwitch", InputSwitch);
Vue.directive("tooltip", Tooltip);
Vue.component("InputText", InputText);
Vue.component("InputNumber", InputNumber);
Vue.component("Paginator", Paginator);
Vue.component("TreeTable", TreeTable);
Vue.component("Tree", Tree);

// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        return new Promise((resolve, reject) => {
            console.log(process.env.EMAIL_APIKey);
            if (AuthService.isAccessTokenExpired()) {
                console.log("Token expired, refreshing...");

                AuthService.refreshSession()
                    .then((authResult) => {
                        console.log("Auth result", authResult);
                        config.headers[
                            "Authorization"
                            ] = `Bearer ${authResult.accessToken}`;
                        resolve(config);
                    })
                    .catch((err) => {
                        console.error("Unable to refresh token", err);
                        reject(err);
                        router.push("/login");
                    });
            } else {
                config.headers[
                    "Authorization"
                    ] = `Bearer ${AuthService.getAccessToken()}`;
                resolve(config);
            }
        });
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
