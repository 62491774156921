import axios from "axios";

const contactUrl = "/v2/api/contacts";
const equipmentUrl = "/v2/api/equipment";

class ContactService {
  // Get all Contacts
  static getContacts(pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};

        let url = [
          contactUrl,
          `?page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "name"}`,
          `&order=${pagingOpts.order || "asc"}`,
        ].join("");

        let result = await axios.get(url);
        let pagingData = result.data;
        pagingData.data.forEach((c) => {
          if (c.addresses && c.addresses.length > 0) {
            c.default_address =
              c.addresses.find((addr) => addr.is_default) || c.addresses[0];
          }
        });

        resolve(pagingData);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Contact by Id
  static getContactById(contactId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}?expand=agent`;
        let result = await axios.get(url);

        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static findContactByName(name) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}?name=${name}`;
        let result = await axios.get(url);

        let pagingData = result.data;
        pagingData.data.forEach((c) => {
          if (c.addresses && c.addresses.length > 0) {
            c.default_address =
              c.addresses.find((addr) => addr.is_default) || c.addresses[0];
          }
        });

        resolve(pagingData);
        /* resolve(result.data); */
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Search Contact
  static searchContact(searchTerm, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};
        let url = [
          contactUrl,
          `?searchTerm=${encodeURIComponent(searchTerm)}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
        ].join("");

        let result = await axios.get(url);

        let pagingData = result.data;
        pagingData.data.forEach((c) => {
          if (c.addresses && c.addresses.length > 0) {
            c.default_address =
              c.addresses.find((addr) => addr.is_default) || c.addresses[0];
          }
        });

        resolve(pagingData);
        //resolve(result.data);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  // Lookup Contact
  static lookupContact(searchTerm) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${contactUrl}?searchTerm=${encodeURIComponent(
            searchTerm
          )}&select=contact_id,name&limit=100`
        );

        resolve(result.data);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  static searchContactsWithType(queryParams) {
    return new Promise(async (resolve, reject) => {
      try {
        const queryString = Object.keys(queryParams).map(key => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
        }).join('&');

        let result = await axios.get(`${contactUrl}/search?${queryString}`);

        resolve(result.data);
      } catch (error) {
        console.log("ERROR: ", error);
        reject(error.response);
      }
    });
  }

  static createPerson(person) {}

  // Get Equipment by Owner
  static getEquipment(contactId, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};

        let url = [
          equipmentUrl,
          `?ownerId=${contactId}`,
          `&page=${pagingOpts.page || 1}`,
          `&limit=${pagingOpts.limit || 50}`,
          `&orderBy=${pagingOpts.orderBy || "created_at"}`,
          `&order=${pagingOpts.order || "desc"}`,
        ].join("");

        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Documents
  static getDocuments(contactId) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${contactUrl}/${contactId}/documents`;
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  static getIvyClients(vtmsOrgId, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(vtmsOrgId);
        let url = [
          `/v2/api/vtms/clients?orgId=${vtmsOrgId}`,
          `&page=${pagingOpts.currentPage || 1}`,
          `&limit=${pagingOpts.pageSize || 50}`,
          `&orderBy=${pagingOpts.field || "activated_at"}`,
          `&order=${pagingOpts.isAsc ? "asc" : "desc"}`,
        ].join("");
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}

export default ContactService;
