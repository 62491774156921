import axios from "axios";

const quotationUrl = "/v2/api/quotations";

class QuotationService {
    static getQuotations(urlP) {
        return new Promise(async (resolve, reject) => {
            try {
                let url = [quotationUrl].join("");
                url = url + urlP;
                let result = await axios.get(url);
                let pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getQuotationById(quotationId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${quotationUrl}/${quotationId}`;
                const result = await axios.get(url);
                const quotationData = result.data;
                resolve(quotationData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getQuotationByCustomerId(customerId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${quotationUrl}/by-customer/${customerId}`;
                const result = await axios.get(url);
                const quotationsData = result.data;
                resolve(quotationsData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createQuotation(quotationData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${quotationUrl}`;
                const result = await axios.post(url, quotationData);
                const createdQuotation = result.data;
                resolve(createdQuotation);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateQuotation(quotationId, updatedQuotationData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${quotationUrl}/${quotationId}`;
                await axios.put(url, updatedQuotationData);
                resolve(`Quotation record with ID ${quotationId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteQuotation(quotationId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${quotationUrl}/${quotationId}`;
                await axios.delete(url);
                resolve(`Quotation record with ID ${quotationId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static convertQuotationToJobSheet(quotationId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${quotationUrl}/${quotationId}/convert-to-job-sheet`;
                const result = await axios.post(url);
                const createdJobSheet = result.data;
                resolve(createdJobSheet);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static uploadDocuments(files, quotationId) {
        return new Promise(async (resolve, reject) => {
            try {
                // Prepare an array of file data objects
                const filesToUpload = files.map((file) => ({
                    filename: file.name,
                    content_type: file.content_type,
                    visibility: "Internal",
                    category: "quotations",
                    name: file.name,
                    unique_id: file.unique_id,
                    requires_thumbnail: (file.requires_thumbnail !== undefined) ? file.requires_thumbnail : false,
                    document_role: (file.document_role !== undefined) ? file.document_role : 'attachment',
                }));

                console.log("Files are:", files);
                console.log("Documents to upload are:", filesToUpload);

                const quotationDocumentUrl = `${quotationUrl}/${quotationId}/documents`;

                // Post the array of file data objects to the server
                let result = await axios.post(quotationDocumentUrl, filesToUpload);
                console.log(result);
                resolve(result.data);
            } catch (error) {
                console.error("Error uploading documents:", error);
                reject(error.response);
            }
        });
    }

    static async getQuotationDocuments(quotationId, documentRole) {
        try {
            const quotationDocumentUrl = `${quotationUrl}/${quotationId}/documents`;

            const quotationDocumentUrlWithRole = documentRole ? `${quotationDocumentUrl}?document_role=${encodeURIComponent(documentRole)}` : quotationDocumentUrl;
            // Fetch the documents
            let result = await axios.get(quotationDocumentUrlWithRole);
            return result.data;
        } catch (error) {
            console.error("Error fetching quotation documents:", error);
            throw error;
        }
    }

    static async deleteQuotationDocuments(quotationId, documentIds) {
        try {
            const quotationDocumentUrl = `${quotationUrl}/${quotationId}/documents`;

            // Prepare the request body
            await axios.delete(quotationDocumentUrl, {
                data: documentIds,
                headers: {"Content-Type": "application/json"},
            });

            console.log(`Documents deleted successfully for quotationId: ${quotationId}`);
        } catch (error) {
            console.error("Error deleting quotation documents:", error);
            throw error;
        }
    }
}

export default QuotationService;
