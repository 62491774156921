import axios from "axios";

const certificateUrl = "/v2/api/certificate";

class CertificateService {
    static searchAllCertificates(urlParams) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${certificateUrl}${urlParams}`;
                const result = await axios.get(url);
                const pagingData = result.data;
                resolve(pagingData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static getCertificateById(certificateId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${certificateUrl}/${certificateId}`;
                const result = await axios.get(url);
                const certificateData = result.data;
                resolve(certificateData);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static createCertificate(certificateData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = certificateUrl;
                const result = await axios.post(url, certificateData);
                const createdCertificate = result.data;
                resolve(createdCertificate);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static updateCertificate(certificateId, updatedCertificateData) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${certificateUrl}/${certificateId}`;
                await axios.put(url, updatedCertificateData);
                resolve(`Certificate record with ID ${certificateId} updated successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static deleteCertificate(certificateId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${certificateUrl}/${certificateId}`;
                await axios.delete(url);
                resolve(`Certificate record with ID ${certificateId} deleted successfully`);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    static async getReport(certificateId, reportType) {
        try {
            const url = `${certificateUrl}/${certificateId}/report/${reportType}`;
            const response = await axios.post(url, {}, {responseType: 'blob'});
            return response.data;
        } catch (error) {
            throw new Error(error.response ? error.response.data : error.message);
        }
    }
}

export default CertificateService;
